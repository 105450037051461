import { Box } from '@mui/material'
import { isEmpty } from 'lodash'

import lightTheme from '../config/theme'
import GoogleMap from '../vendor/googlemaps'

const pinPath =
  'M18,0 C8.0588745,0 0,7.5216162 0,16.8 C0,29.4 18,48 18,48 C18,48 36,29.4 36,16.8 C36,7.5216162 27.9411255,0 18,0 Z'

export const getFormattedPosition = (position) => ({
  lat: position.lat ?? position.latitude,
  lng: position.lng ?? position.longitude,
})

export const getMapMarker = (position, iconPath, info, reverse = false) => {
  if (!position) return null

  const iconTransform = reverse ? 'scale(-1, 1) translate(-24, 0)' : ''
  const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="48">
      <path d="${pinPath}" fill="${lightTheme.palette.primary[500]}" stroke="white" stroke-width="1" />
      <path d="${iconPath}" fill="white" transform="translate(6, 6) ${iconTransform}" />
    </svg>
  `

  return {
    info,
    position: getFormattedPosition(position),
    icon: {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`,
      scaledSize: new window.google.maps.Size(36, 48),
      anchor: new window.google.maps.Point(18, 48), // adjust anchor based on the size
    },
  }
}

export const getMapCircle = (geofencing, position) => ({
  radius: geofencing,
  center: getFormattedPosition(position),
})

const Map = ({ height = 360, markers, circles, polylines }) => (
  <Box sx={{ position: 'relative', width: '100%', height }}>
    {!isEmpty(markers) ? <GoogleMap markers={markers} circles={circles} polylines={polylines} /> : null}
  </Box>
)

export default Map

import { Box } from '@mui/material'
import classnames from 'classnames'
import { ImageField, ReferenceField, SimpleShowLayout, useRecordContext } from 'react-admin'

import MarkerField from './field'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UserFullNameField from '../../components/UserFullNameField'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'
import { ShowReference, ShowReferenceLinks, useShowStyles } from '../common/show'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import { VehicleShowLayout } from '../vehicles/show'

const DamageReportShowLayout = () => {
  const record = useRecordContext()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)

  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <ShowCardHeader />
      <div className={isSmallScreen ? null : showClasses.row}>
        <div className={showClasses.expanded}>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <OrganisationField />
            <HubField />
            {hasReadForUsers && (
              <ReferenceField source="reporter_id" reference="users" link="show" emptyText="n/a">
                <UserFullNameField />
              </ReferenceField>
            )}
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField
              source="category"
              fallback="resources.damage-reports.enums.category.none"
              map={DAMAGES_CATEGORIES}
            />
            <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
            <DateTooltipField source="created_on" addTime />
            <DateTooltipField source="updated_on" addTime />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField source="note" fallback="resources.damage-reports.show.note.fallback" multiline />
          </SimpleShowLayout>
        </div>
        {(record.blueprint_id || record.picture) && (
          <Box
            width="100%"
            maxWidth={isSmallScreen ? null : 480}
            className={isSmallScreen ? null : commonClasses.borderLeft}
          >
            {record.blueprint_id && (
              <SimpleShowLayout
                className={classnames(showClasses.fieldContainer, isSmallScreen ? null : commonClasses.center)}
              >
                <MarkerField containerWidth={isSmallScreen ? 300 : 350} markerSize={isSmallScreen ? 24 : 28} />
              </SimpleShowLayout>
            )}
            {record.picture && (
              <SimpleShowLayout
                className={classnames(showClasses.fieldContainer, isSmallScreen ? null : commonClasses.center)}
              >
                <ImageField
                  source="picture"
                  label={false}
                  sx={{
                    '& img': {
                      width: '100% !important',
                      height: '100% !important',
                      maxWidth: '440px !important',
                      maxHeight: '400px !important',
                      borderRadius: '3px !important',
                      marginBottom: '0px !important',
                      marginTop: '14px !important',
                    },
                  }}
                />
              </SimpleShowLayout>
            )}
          </Box>
        )}
      </div>
      <ShowReferenceLinks excluded={['vehicles']} sources={{ [usersConfig.options.referenceKey]: 'reporter_id' }} />
    </>
  )
}

const DamageReportAside = () => (
  <ShowReference reference="vehicles" source="vehicle_id">
    <VehicleShowLayout excluded={['organisations']} hasRedirect />
  </ShowReference>
)

export default () => (
  <AdvancedShow aside={<DamageReportAside />}>
    <DamageReportShowLayout />
  </AdvancedShow>
)

import { SelectArrayInput, SelectInput } from 'react-admin'

import config from './config'
import VehicleReferenceInput from './input'
import { VEHICLE_STATUS_CODES, VEHICLE_TYPES } from '../../config/vehicles'

const vehicleTypeChoices = Object.entries(VEHICLE_TYPES).map(([k, v]) => ({ value: k, name: v }))

export const VehicleTypeFilter = (props) => <SelectInput {...props} choices={vehicleTypeChoices} optionValue="value" />

VehicleTypeFilter.defaultProps = { source: 'type', label: 'resources.vehicles.fields.type' }

const vehicleLockedChoices = [
  { value: true, name: 'resources.vehicles.enums.locked.true' },
  { value: false, name: 'resources.vehicles.enums.locked.false' },
]

export const VehicleLockFilter = (props) => (
  <SelectInput {...props} choices={vehicleLockedChoices} label="resources.vehicles.fields.locked" optionValue="value" />
)

VehicleLockFilter.defaultProps = { source: 'locked' }

export const VehicleIssueFilter = () => (
  <SelectArrayInput
    source="issues"
    choices={Object.entries(VEHICLE_STATUS_CODES).map(([k, v]) => ({ id: k, name: v }))}
    options={{ sx: { '& .MuiInputBase-input': { overflow: 'scroll', maxHeight: '23px' } } }}
    sx={{ '& .RaSelectArrayInput-chips': { flexWrap: 'nowrap' }, '& .RaSelectArrayInput-chip': { marginTop: '1px' } }}
  />
)

const VehiclesFilter = (props, filter) => (
  <VehicleReferenceInput validate={null} filter={{ active: null, ...filter }} {...props} />
)

VehiclesFilter.defaultProps = { source: config.options.referenceKey }

export default VehiclesFilter

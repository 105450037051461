import get from 'lodash/get'
import { FunctionField } from 'react-admin'

const BookingRatingField = (props) => (
  <FunctionField
    render={(record) => {
      const numberOfFullStars = get(record, props.source)
      const numberOfEmptyStars = 5 - numberOfFullStars
      return typeof numberOfFullStars === 'number'
        ? '★'.repeat(numberOfFullStars) + '☆'.repeat(numberOfEmptyStars)
        : 'n/a'
    }}
  />
)

export default BookingRatingField

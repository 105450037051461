import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { get, isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

import StaticText from './StaticText'
import { BOOKING_END_CHECKS } from '../config/bookingEndChecks'
import { useShowStyles } from '../resources/common/show'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

const BookingEndChecksField = () => {
  const record = useRecordContext()
  const recordChecks = get(record, 'booking_end_checks', [])
  const showClasses = useShowStyles()
  const classes = useStyles()
  const translate = useTranslate()

  if (isEmpty(recordChecks)) {
    return <StaticText>resources.organisations.enums.bookingEndChecks.empty</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {recordChecks.map((recordCheck) => {
        const check = BOOKING_END_CHECKS[recordCheck]
        return (
          <li key={check} className={classnames(showClasses.unorderedListItem, classes.unorderedListItem)}>
            <p>{translate(check)}</p>
          </li>
        )
      })}
    </ul>
  )
}

BookingEndChecksField.defaultProps = {
  label: 'resources.organisations.enums.bookingEndChecks.name',
}

export default BookingEndChecksField

import RejectedIcon from '@mui/icons-material/CancelRounded'
import AcceptedIcon from '@mui/icons-material/CheckCircleRounded'
import WaitingDataIcon from '@mui/icons-material/ErrorRounded'
import MissingDataIcon from '@mui/icons-material/HelpRounded'
import PendingIcon from '@mui/icons-material/WatchLaterRounded'

import FileGeneratedIcon from '../components/icons/FileCheckedIcon'
import FileScheduledIcon from '../components/icons/FileClockIcon'
import FileFailedIcon from '../components/icons/FileRemoveIcon'

// Payout setup statuses

export const PAYOUT_SETUP_STATUS_NOT_STARTED = 'NOT_STARTED'
export const PAYOUT_SETUP_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const PAYOUT_SETUP_STATUS_COMPLETED = 'COMPLETED'
export const PAYOUT_SETUP_STATUS_FAILED = 'FAILED'

// Payout bank account statuses
// Note for ourselves as Payaut documentation is actually not documented:
// WAITING_DATA & MISSING_DATA statuses should not appear as long as KYB process is completed

export const PAYOUT_BANK_ACCOUNT_STATUS_WAITING_DATA = 'WAITING_DATA'
export const PAYOUT_BANK_ACCOUNT_STATUS_MISSING_DATA = 'MISSING_DATA'
export const PAYOUT_BANK_ACCOUNT_STATUS_DATA_RECEIVED = 'DATA_RECEIVED'
export const PAYOUT_BANK_ACCOUNT_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const PAYOUT_BANK_ACCOUNT_STATUS_PASSED = 'PASSED'
export const PAYOUT_BANK_ACCOUNT_STATUS_REJECTED = 'REJECTED'
export const PAYOUT_BANK_ACCOUNT_STATUS_FORGED = 'FORGED'
export const PAYOUT_BANK_ACCOUNT_STATUS_FAILED = 'FAILED'

export const PAYOUT_BANK_ACCOUNT_STATUSES_LABELS = {
  [PAYOUT_BANK_ACCOUNT_STATUS_WAITING_DATA]: 'Waiting data',
  [PAYOUT_BANK_ACCOUNT_STATUS_MISSING_DATA]: 'Missing data',
  [PAYOUT_BANK_ACCOUNT_STATUS_DATA_RECEIVED]: 'Pending',
  [PAYOUT_BANK_ACCOUNT_STATUS_IN_PROGRESS]: 'Pending',
  [PAYOUT_BANK_ACCOUNT_STATUS_PASSED]: 'Accepted',
  [PAYOUT_BANK_ACCOUNT_STATUS_REJECTED]: 'Rejected',
  [PAYOUT_BANK_ACCOUNT_STATUS_FORGED]: 'Rejected',
  [PAYOUT_BANK_ACCOUNT_STATUS_FAILED]: 'Rejected',
}

export const PAYOUT_BANK_ACCOUNT_STATUSES_ICONS = {
  [PAYOUT_BANK_ACCOUNT_STATUS_WAITING_DATA]: WaitingDataIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_MISSING_DATA]: MissingDataIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_DATA_RECEIVED]: PendingIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_IN_PROGRESS]: PendingIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_PASSED]: AcceptedIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_REJECTED]: RejectedIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_FORGED]: RejectedIcon,
  [PAYOUT_BANK_ACCOUNT_STATUS_FAILED]: RejectedIcon,
}

export const PAYOUT_BANK_ACCOUNT_STATUSES_COLORS = {
  [PAYOUT_BANK_ACCOUNT_STATUS_WAITING_DATA]: 'warning',
  [PAYOUT_BANK_ACCOUNT_STATUS_MISSING_DATA]: 'warning',
  [PAYOUT_BANK_ACCOUNT_STATUS_DATA_RECEIVED]: 'grey',
  [PAYOUT_BANK_ACCOUNT_STATUS_IN_PROGRESS]: 'grey',
  [PAYOUT_BANK_ACCOUNT_STATUS_PASSED]: 'primary',
  [PAYOUT_BANK_ACCOUNT_STATUS_REJECTED]: 'error',
  [PAYOUT_BANK_ACCOUNT_STATUS_FORGED]: 'error',
  [PAYOUT_BANK_ACCOUNT_STATUS_FAILED]: 'error',
}

// Payout report statuses

export const PAYOUT_REPORT_STATUS_SCHEDULED = 'SCHEDULED'
export const PAYOUT_REPORT_STATUS_GENERATED = 'GENERATED'
export const PAYOUT_REPORT_STATUS_FAILED = 'FAILED'

export const PAYOUT_REPORT_STATUSES_LABELS = {
  [PAYOUT_REPORT_STATUS_SCHEDULED]: 'Scheduled',
  [PAYOUT_REPORT_STATUS_GENERATED]: 'Generated',
  [PAYOUT_REPORT_STATUS_FAILED]: 'Failed',
}

export const PAYOUT_REPORT_STATUSES_ICONS = {
  [PAYOUT_REPORT_STATUS_SCHEDULED]: FileScheduledIcon,
  [PAYOUT_REPORT_STATUS_GENERATED]: FileGeneratedIcon,
  [PAYOUT_REPORT_STATUS_FAILED]: FileFailedIcon,
}

export const PAYOUT_REPORT_STATUSES_COLORS = {
  [PAYOUT_REPORT_STATUS_SCHEDULED]: 'grey',
  [PAYOUT_REPORT_STATUS_GENERATED]: 'primary',
  [PAYOUT_REPORT_STATUS_FAILED]: 'error',
}

import classnames from 'classnames'
import { Filter, List, NumberField, RecordRepresentation, ReferenceField, SimpleShowLayout } from 'react-admin'

import config from './config'
import { DiscountField } from './field'
import { VoucherTypeFilter } from './filter'
import VoucherFormLayout, { transformValues } from './form'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import UserFullNameField from '../../components/UserFullNameField'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPES } from '../../config/vouchers'
import { isVoucherExpired } from '../../domain/vouchers'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import { useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'

const VouchersFilters = (props) => (
  <Filter {...props}>
    <VoucherTypeFilter alwaysOn />
  </Filter>
)

const voucherRowSx = (record) => {
  const isExpired = isVoucherExpired(record)
  return { opacity: isExpired ? 0.4 : 1 }
}

const VoucherPanel = (props) => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()

  return (
    <>
      <SimpleShowLayout {...props} className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
        <NumberField source="number_of_entries" />
        <NumberField source="number_of_uses" />
        <ReferenceField source="user_id" reference="users" link="show" emptyText="n/a">
          <UserFullNameField />
        </ReferenceField>
        <OrganisationField emptyText="n/a" />
      </SimpleShowLayout>
    </>
  )
}

const VouchersListLayout = () => (
  <GenericListLayout
    compactListLayout={
      <CompactList
        linkType="show"
        itemSx={voucherRowSx}
        icon={<config.icon />}
        title={<RecordRepresentation />}
        body={() => (
          <>
            <AdvancedTextField source="type" map={VOUCHER_TYPES} />
            {' • '}
            <DiscountField />
            <br />
            <PeriodField startedOnSource="start_date" endedOnSource="end_date" addTime />
          </>
        )}
      />
    }
    regularListLayout={
      <ListDatagrid rowSx={voucherRowSx} expand={<VoucherPanel />}>
        <AdvancedTextField source="code" />
        <AdvancedTextField source="name" />
        <AdvancedTextField source="type" map={VOUCHER_TYPES} />
        <DiscountField />
        <DateTooltipField source="start_date" addTime />
        <DateTooltipField source="end_date" addTime />
        <DateTooltipField source="created_on" addTime />
        <AdvancedEditInDialogButton transform={transformValues}>
          <VoucherFormLayout />
        </AdvancedEditInDialogButton>
      </ListDatagrid>
    }
  />
)

export default () => {
  return (
    <List
      empty={false}
      sort={config.options.defaultSort}
      filters={<VouchersFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      actions={
        <ListActions>
          <AdvancedCreateButton transform={transformValues}>
            <VoucherFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <VouchersListLayout />
    </List>
  )
}

import { Grid, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { SimpleShowLayout, useRecordContext } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'
import ShowCardHeader from '../../components/ShowCardHeader'
import { useShowStyles } from '../common/show'

const PicturesShowLayout = ({ pictures }) => {
  const translate = useTranslate()
  return !isEmpty(pictures) ? (
    <Grid container direction="row" alignItems="center" spacing={2}>
      {Object.values(pictures).map((value) => (
        <Grid key={value} item sm={12} md={6} lg={3}>
          <a href={value} target="_blank" rel="noopener noreferrer">
            <img src={value} alt={value} width="100%" height="100%" style={{ borderRadius: 3 }} />
          </a>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography component="span" variant="body2">
      {translate('resources.damage-checks.noPicturesProvided')}
    </Typography>
  )
}

export const DamageCheckShowLayout = (props) => {
  const record = useRecordContext(props)
  const showClasses = useShowStyles()
  return (
    <>
      <ShowCardHeader title="resources.damage-checks.name" titleArgs={1} />
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <PicturesShowLayout pictures={record.pictures} />
      </SimpleShowLayout>
      <SimpleShowLayout record={record} className={showClasses.fieldContainer}>
        <AdvancedTextField
          source="description"
          fallback="resources.damage-checks.show.description.fallback"
          multiline
        />
      </SimpleShowLayout>
    </>
  )
}

import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import {
  ArrayInput,
  CREATE,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useGetIdentity,
  useRecordContext,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import AddressInputMain from '../../components/AddressInputMain'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedTextInput from '../../components/AdvancedTextInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import BillingTypesRadioButtonGroupInput from '../../components/BillingTypesRadioButtonGroupInput'
import DefaultBillingTypeSelectInput from '../../components/DefaultBillingTypeSelectInput'
import DefinitionList from '../../components/DefinitionList'
import {
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
  ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE,
} from '../../config/accounts'
import { ADDRESS_FIELD_COUNTRY_CODE } from '../../config/addresses'
import { BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPE_PAID, BOOKING_BILLING_TYPES } from '../../config/bookings'
import { OPS_USER_ROLE_OWNER } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import {
  ACCOUNT_BILLING_OVERRIDE_SOURCE,
  BILLING_TYPES_OVERRIDE_SOURCE,
  DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE,
  FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE,
  HAS_MOBILITY_BUDGET_OVERRIDE_SOURCE,
  REQUIRES_PAYMENT_OVERRIDE_SOURCE,
  USER_NFC_CARD_TYPES,
  USER_ROLE_USER,
  USER_ROLES,
  USER_SMS_LANGUAGE_EN,
  USER_SMS_LANGUAGES,
  USERS_PERMISSIONS,
} from '../../config/users'
import { hasBillingType, hasMobilityBudgetBillingType, parsePhoneNumber } from '../../utils'
import { useSmallScreen } from '../../utils/theme'
import { validateAddress, validateEmail, validatePhoneNumber, validateUuid, validateVAT } from '../../utils/validators'
import { useGetCurrentAccount } from '../accounts/hooks'
import { FormDivider } from '../common'
import { AdvancedSimpleForm, SimpleFormIteratorAddButton } from '../common/forms'

const userRoleChoices = Object.entries(USER_ROLES).map(([k, v]) => ({ id: k, name: v }))
const smsLanguageChoices = Object.entries(USER_SMS_LANGUAGES).map(([k, v]) => ({ id: k, name: v }))
const nfcTypesChoices = Object.entries(USER_NFC_CARD_TYPES).map(([k, v]) => ({ id: k, name: v }))

const shouldDisplayAccountAttributesSection = (identity) => !Boolean(identity.restricted_to_organisation_id)

const shouldDisplayCompanyLegalInfoSection = (formData) => {
  const accountHasBillingTypePaid = hasBillingType(
    formData.account[ACCOUNT_BILLING_TYPES_SOURCE],
    BOOKING_BILLING_TYPE_PAID,
  )
  const userHasBillingOverride = formData[ACCOUNT_BILLING_OVERRIDE_SOURCE]
  const userHasBillingTypePaidInOverride = hasBillingType(
    formData[BILLING_TYPES_OVERRIDE_SOURCE],
    BOOKING_BILLING_TYPE_PAID,
  )
  return (
    (!userHasBillingOverride && accountHasBillingTypePaid) ||
    (userHasBillingOverride && userHasBillingTypePaidInOverride)
  )
}

const shouldDisplayMobilityBudgetSection = (formData) => {
  const accountHasMobilityBudget = hasMobilityBudgetBillingType(formData.account[ACCOUNT_BILLING_TYPES_SOURCE])
  const userHasBillingOverride = formData[ACCOUNT_BILLING_OVERRIDE_SOURCE]
  const userHasMobilityBudgetInOverride = hasMobilityBudgetBillingType(formData[BILLING_TYPES_OVERRIDE_SOURCE])
  return (
    formData.identity.role === OPS_USER_ROLE_OWNER &&
    ((!userHasBillingOverride && accountHasMobilityBudget) || userHasMobilityBudgetInOverride)
  )
}

export const transformValues = ({
  account,
  account_billing_override,
  billing_types_override,
  company_legal_info,
  default_billing_type_override,
  free_billing_type_requires_justification_override,
  has_company_legal_info,
  has_mobility_budget_override,
  identity,
  nfc_cards,
  phone_number,
  requires_payment_override,
  send_sms,
  sms_locale,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        phone_number: phone_number || null,
        send_sms: phone_number ? send_sms : false,
        sms_locale: phone_number && send_sms ? sms_locale : null,
        [REQUIRES_PAYMENT_OVERRIDE_SOURCE]: account_billing_override ? requires_payment_override : null,
        [BILLING_TYPES_OVERRIDE_SOURCE]: account_billing_override ? billing_types_override : null,
        [DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE]: account_billing_override ? default_billing_type_override : null,
        [FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE]: account_billing_override
          ? free_billing_type_requires_justification_override
          : null,
        company_legal_info: has_company_legal_info ? company_legal_info : null,
        nfc_cards: nfc_cards
          ? nfc_cards.map((card) => ({ ...card, card_id: card.card_id.replace(/[^a-zA-Z0-9]/g, '') }))
          : [],
      }
    : restValues

const SendSmsSwitch = () => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const { setValue } = useFormContext()
  const phoneNumber = useWatch({ name: 'phone_number' })

  useEffect(() => {
    if (!Boolean(phoneNumber)) {
      setValue('send_sms', false)
      setValue('sms_locale', null)
    }
  }, [Boolean(phoneNumber), setValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggle = (event) => {
    setValue('send_sms', event.target.checked)
    if (event.target.checked) {
      setValue('sms_locale', USER_SMS_LANGUAGE_EN)
    } else {
      setValue('sms_locale', null)
    }
  }

  return (
    <AdvancedBooleanInput
      source="send_sms"
      readOnly={!Boolean(phoneNumber)}
      helperText={Boolean(phoneNumber) ? null : undefined}
      className={isSmallScreen ? null : commonClasses.doubleInput}
      options={{ onChange: onToggle }}
    />
  )
}

const AccountBillingOverrideBooleanInput = ({ account }) => {
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const { setValue } = useFormContext()
  const translate = useTranslate()

  const [
    accountBillingOverride,
    requiresPaymentOverride,
    billingTypesOverride,
    defaultBillingTypeOverride,
    freeBillingTypeRequiresJustificationOverride,
  ] = useWatch({
    name: [
      ACCOUNT_BILLING_OVERRIDE_SOURCE,
      REQUIRES_PAYMENT_OVERRIDE_SOURCE,
      BILLING_TYPES_OVERRIDE_SOURCE,
      DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE,
      FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE,
    ],
  })

  const [isFormReady, setIsFormReady] = useState(false)
  useEffect(() => {
    setIsFormReady(true)
  }, [])
  useEffect(() => {
    if (isFormReady && accountBillingOverride === false) {
      if (requiresPaymentOverride == null) setValue(REQUIRES_PAYMENT_OVERRIDE_SOURCE, account.requires_payment)
      if (billingTypesOverride == null) setValue(BILLING_TYPES_OVERRIDE_SOURCE, account[ACCOUNT_BILLING_TYPES_SOURCE])
      if (defaultBillingTypeOverride == null)
        setValue(DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE, account[ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE])
      if (freeBillingTypeRequiresJustificationOverride == null)
        setValue(
          FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE,
          account[ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE],
        )
    }
  }, [isFormReady]) // eslint-disable-line react-hooks/exhaustive-deps

  const helperText = translate('resources.users.forms.helperTexts.account_billing_override', {
    billingTypes: account[ACCOUNT_BILLING_TYPES_SOURCE].map(
      (t) => translate(BOOKING_BILLING_TYPES[t]) + (t === account[ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE] ? '*' : ''),
    ).join('/'),
    paymentMethodRequired: translate(`ra.boolean.${account.requires_payment}`),
  })

  return (
    <AdvancedBooleanInput
      source={ACCOUNT_BILLING_OVERRIDE_SOURCE}
      className={isSmallScreen ? null : commonClasses.doubleInput}
      helperText={helperText}
    />
  )
}

const UserFormLayout = ({ type = UPDATE }) => {
  const account = useGetCurrentAccount()
  const { identity } = useGetIdentity()
  const record = useRecordContext()
  const translate = useTranslate()

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()

  if (!account || !identity) {
    return null
  }

  const defaultValues = {
    account,
    identity,
    role: USER_ROLE_USER,
    send_sms: false,
    [ACCOUNT_BILLING_OVERRIDE_SOURCE]: type === UPDATE ? record[BILLING_TYPES_OVERRIDE_SOURCE] !== null : false,
    [HAS_MOBILITY_BUDGET_OVERRIDE_SOURCE]: hasMobilityBudgetBillingType(
      record?.[BILLING_TYPES_OVERRIDE_SOURCE] ?? account[ACCOUNT_BILLING_TYPES_SOURCE],
    ),
    has_company_legal_info: type === UPDATE ? record.company_legal_info !== null : false,
  }

  return (
    <AdvancedSimpleForm defaultValues={defaultValues} toolbar={<BasicFormToolbar formType={type} />} type={type}>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" validate={validateEmail()} type="email" />
      <FormDataConsumer>
        {({ formData }) => {
          const countryCode = formData.address?.[ADDRESS_FIELD_COUNTRY_CODE]
          return (
            <TextInput
              type="tel"
              source="phone_number"
              parse={(value) => (value === '' ? null : parsePhoneNumber(value, countryCode) || value)}
              validate={validatePhoneNumber({ countryCode })}
              helperText={
                formData.phone_number?.charAt(0) === '+' ? null : 'resources.users.forms.helperTexts.phone_number'
              }
            />
          )
        }}
      </FormDataConsumer>
      <AddressInputMain source="address" validate={validateAddress(false)} />
      <FormDivider />
      <SelectInput source="role" choices={userRoleChoices} validate={required()} />
      <AdvancedSelectInput source="is_trusted" />
      <DefinitionList items={USERS_PERMISSIONS} />
      {type === CREATE && (
        <>
          <FormDivider />
          <SendSmsSwitch />
          <FormDataConsumer>
            {({ formData }) =>
              Boolean(formData.send_sms) && (
                <SelectInput source="sms_locale" choices={smsLanguageChoices} validate={required()} />
              )
            }
          </FormDataConsumer>
        </>
      )}
      <FormDataConsumer>
        {({ formData }) =>
          shouldDisplayAccountAttributesSection(formData.identity) && (
            <>
              <FormDivider />
              <AccountBillingOverrideBooleanInput account={account} />
              {formData[ACCOUNT_BILLING_OVERRIDE_SOURCE] && (
                <>
                  <FormDivider />
                  <AdvancedSelectInput source={HAS_MOBILITY_BUDGET_OVERRIDE_SOURCE} />
                  <AdvancedSelectInput source={REQUIRES_PAYMENT_OVERRIDE_SOURCE} />
                  <FormDivider />
                  <BillingTypesRadioButtonGroupInput
                    source={BILLING_TYPES_OVERRIDE_SOURCE}
                    hasMobilityBudgetSource={HAS_MOBILITY_BUDGET_OVERRIDE_SOURCE}
                  />
                  <DefaultBillingTypeSelectInput
                    source={DEFAULT_BILLING_TYPE_OVERRIDE_SOURCE}
                    billingTypesSource={BILLING_TYPES_OVERRIDE_SOURCE}
                  />
                  {hasBillingType(formData[BILLING_TYPES_OVERRIDE_SOURCE], BOOKING_BILLING_TYPE_FREE) && (
                    <AdvancedSelectInput source={FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_OVERRIDE_SOURCE} />
                  )}
                </>
              )}
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          shouldDisplayCompanyLegalInfoSection(formData) && (
            <>
              <FormDivider />
              <AdvancedBooleanInput
                source="has_company_legal_info"
                className={isSmallScreen ? null : commonClasses.doubleInput}
              />
              {formData.has_company_legal_info && (
                <>
                  <FormDivider />
                  <TextInput source="company_legal_info.name" validate={required()} />
                  <TextInput source="company_legal_info.vat_number" validate={validateVAT()} />
                  <AddressInputMain source="company_legal_info.address" validate={validateAddress()} />
                </>
              )}
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          shouldDisplayMobilityBudgetSection(formData) && (
            <>
              <FormDivider />
              <AdvancedTextInput
                source="mbrella_id"
                validate={validateUuid(translate('resources.users.fields.mbrella_id'))}
              />
              <AdvancedTextInput
                source="skipr_id"
                validate={validateUuid(translate('resources.users.fields.skipr_id'))}
              />
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          shouldDisplayAccountAttributesSection(formData.identity) && (
            <>
              <FormDivider />
              <AdvancedTextInput source="monitoring_email" validate={validateEmail(false)} type="email" />
            </>
          )
        }
      </FormDataConsumer>

      <FormDivider />

      <ArrayInput source="nfc_cards" label="resources.users.fields.nfc_cards.name" helperText={false}>
        <SimpleFormIterator
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          addButton={<SimpleFormIteratorAddButton />}
          inline
        >
          <SelectInput
            source="nfc_type"
            label="resources.users.fields.nfc_cards.nfc_type"
            choices={nfcTypesChoices}
            validate={required()}
          />
          <TextInput source="card_id" label="resources.users.fields.nfc_cards.card_id" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </AdvancedSimpleForm>
  )
}

export default UserFormLayout

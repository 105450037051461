import HubFormLayout, { transformValues } from './form'
import { HubTitle } from './show'
import GenericEditForm from '../common/edit'

export default () => (
  <GenericEditForm
    titleComponent={<HubTitle />}
    formLayoutComponent={<HubFormLayout />}
    transformValues={transformValues}
  />
)

import { required } from 'react-admin'
import { useWatch } from 'react-hook-form'

import config from './config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import organisationsConfig from '../organisations/config'

const HubReferenceInput = ({ readOnly = false, ...props }) => {
  const organisationId = useWatch({ name: organisationsConfig.options.referenceKey })
  return (
    <AdvancedReferenceInput
      filter={{ organisation_id: organisationId }}
      readOnly={readOnly || !organisationId}
      reference={config.name}
      sort={config.options.defaultSort}
      source={config.options.referenceKey}
      validate={required()}
      {...props}
    />
  )
}

export default HubReferenceInput

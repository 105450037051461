import { useTranslate } from 'ra-core'
import { useEffect } from 'react'
import { RadioButtonGroupInput, required } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { ACCOUNT_BILLING_TYPES_SOURCE } from '../config/accounts'
import {
  BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET,
  BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD,
} from '../config/bookings'
import { COMMON_INPUT_WIDTH } from '../config/theme'

const BillingTypesRadioButtonGroupInput = ({
  source = ACCOUNT_BILLING_TYPES_SOURCE,
  hasMobilityBudgetSource = 'has_mobility_budget',
  ...props
}) => {
  const translate = useTranslate()
  const { setValue } = useFormContext()
  const [hasMobilityBudget, billingTypes] = useWatch({ name: [hasMobilityBudgetSource, source] })

  const billingTypesChoices = hasMobilityBudget
    ? BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET
    : BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD

  useEffect(() => {
    if (billingTypes && !billingTypesChoices.find((choice) => choice.id === billingTypes.join('/'))) {
      setValue(source, billingTypesChoices[0].id.split('/'))
    }
  }, [hasMobilityBudget, setValue, source]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RadioButtonGroupInput
      {...props}
      row={false}
      source={source}
      format={(v) => (v ? v.join('/') : [])}
      parse={(v) => v.split('/')}
      label={translate('resources.accounts.fields.billing_types', 2)}
      validate={required()}
      helperText={false}
      choices={billingTypesChoices}
      style={{ width: COMMON_INPUT_WIDTH }}
    />
  )
}

export default BillingTypesRadioButtonGroupInput

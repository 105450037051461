import React from 'react'
import { EditButton, Show, TopToolbar, useRecordContext, WithRecord } from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { useResourcePermissions } from '../domain/permissions'
import { ListReference } from '../resources/common/list'
import { Title } from '../resources/common/show'
import eventsConfig from '../resources/events/config'
import { EventsListLayout } from '../resources/events/list'

const ShowActions = () => (
  /* This is a temporary hack as this restriction should ideally come directly
  from backend but the concerned feature is still waiting for validation from clients */
  <TopToolbar>
    <WithRecord render={(record) => !record?.deleted_on && <EditButton />} />
  </TopToolbar>
)
const AdvancedShow = ({ children, aside, ...props }) => {
  const [hasReadForEvents] = useResourcePermissions(eventsConfig.name, SYSTEM_PERMISSION_READ)
  return (
    <Show
      actions={<ShowActions />}
      title={<Title />}
      aside={
        <>
          {aside}
          {hasReadForEvents && (
            <ListReference reference={eventsConfig.name} target="entity_id" sort={eventsConfig.options.defaultSort}>
              <EventsListLayout excludedFields={['entity_id']} />
            </ListReference>
          )}
        </>
      }
      emptyWhileLoading
      {...props}
    >
      {children}
    </Show>
  )
}

export default AdvancedShow

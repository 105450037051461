import { useTheme } from '@mui/styles'
import { useTranslate } from 'ra-core'
import {
  ArrayField,
  ChipField,
  DatagridConfigurable,
  Filter,
  FunctionField,
  List,
  RecordRepresentation,
  SelectColumnsButton,
  SingleFieldList,
  WrapperField,
} from 'react-admin'

import config from './config'
import vehiclesExporter from './exporter'
import { VehicleIssueFilter, VehicleLockFilter, VehicleTypeFilter } from './filter'
import VehicleFormLayout, { transformValues } from './form'
import AdvancedBooleanFilter from '../../components/AdvancedBooleanFilter'
import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import NoteField from '../../components/NoteField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UnitField from '../../components/UnitField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleLockField from '../../components/VehicleLockField'
import { getStatusStyles } from '../../domain/statuses'
import { isVehicleInstalled } from '../../domain/vehicles'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import useGetOrganisationWithHubFilters from '../common/filters'
import { GenericListLayout, ListActions } from '../common/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'

const VehiclesFilters = (props) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  return (
    <Filter {...props}>
      <AdvancedSearchFilter alwaysOn />
      {organisationWithHubFilters}
      <VehicleTypeFilter alwaysOn />
      <AdvancedBooleanFilter source="active" alwaysOn />
      <VehicleLockFilter alwaysOn />
      <VehicleIssueFilter alwaysOn />
    </Filter>
  )
}

const vehicleRowSx = (record) => {
  const isInstalled = isVehicleInstalled(record)
  return { opacity: isInstalled ? 1 : 0.4 }
}

const DTCSField = ({ source }) => {
  return (
    <ArrayField source={source}>
      <SingleFieldList linkType={false}>
        <FunctionField render={(record) => <ChipField record={{ code: record }} source={'code'} />} />
      </SingleFieldList>
    </ArrayField>
  )
}

export const VehiclesListLayout = () => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const theme = useTheme()
  const translate = useTranslate()

  if (hasSingleOrganisation === undefined || hasSingleHub === undefined) {
    return null
  }

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={vehicleRowSx}
          icon={<VehicleIconField />}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={<RecordRepresentation />}
          body={() => (
            <>
              <StaticText>{`${translate('resources.organisations.name', 1)}: `}</StaticText>
              <ReferenceValue reference="organisations" />
              <br />
              <StaticText>{`${translate('resources.hubs.name', 1)}: `}</StaticText>
              <ReferenceValue reference="hubs" />
            </>
          )}
        />
      }
      regularListLayout={
        <DatagridConfigurable rowClick="show" rowSx={vehicleRowSx}>
          <StatusField source="status" />
          {!hasSingleOrganisation && <OrganisationField sortable={false} />}
          {!hasSingleHub && <HubField sortable={false} />}
          <VehicleIconField source="type" color="textSecondary" />
          <AdvancedTextField source="brand" />
          <AdvancedTextField source="model" />
          <AdvancedTextField source="designation" />
          <UnitField source="autonomy" />
          <UnitField source="battery_voltage" formatValue={(v) => v.toFixed(1)} unit="V" />
          <UnitField source="mileage" />
          <UnitField source="remaining_oil_life" formatValue={(v) => v.toFixed(2)} />
          <DTCSField source="dtcs" />
          <VehicleLockField source="locked" />
          <NoteField source="notes" sortable={false} />
          <WrapperField source={translate('ra.action.edit')} label={false} sortable={false}>
            <AdvancedEditInDialogButton transform={transformValues}>
              <VehicleFormLayout />
            </AdvancedEditInDialogButton>
          </WrapperField>
        </DatagridConfigurable>
      }
    />
  )
}

export default () => {
  return (
    <List
      empty={false}
      sort={config.options.defaultSort}
      filters={<VehiclesFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={vehiclesExporter}
      actions={
        <ListActions hasExport>
          <AdvancedCreateButton transform={transformValues}>
            <VehicleFormLayout />
          </AdvancedCreateButton>
          <SelectColumnsButton />
        </ListActions>
      }
    >
      <VehiclesListLayout />
    </List>
  )
}

import PlusIcon from '@mui/icons-material/Add'
import PreviousIcon from '@mui/icons-material/NavigateBefore'
import NextIcon from '@mui/icons-material/NavigateNext'
import MinusIcon from '@mui/icons-material/Remove'
import { Avatar, Box, Button, ButtonGroup, CardHeader } from '@mui/material'
import classnames from 'classnames'
import { get, keyBy, uniq } from 'lodash'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useCreatePath,
  useDataProvider,
  useGetList,
  useGetMany,
  useGetRecordRepresentation,
  useNotify,
} from 'react-admin'
import { renderToString } from 'react-dom/server'

import { getBookingConsumedIcon } from './BookingConsumedField'
import { useAccounts } from '../api/accountsProvider'
import { useApi } from '../api/apiProvider'
import { BOOKING_BILLING_TYPES_ICONS } from '../config/bookings'
import { SYSTEM_PERMISSION_DELETE, SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../config/permissions'
import { STATUS_CODES, STATUS_SEVERITY_CRITICAL } from '../config/statuses'
import { useCommonStyles } from '../config/theme'
import {
  VEHICLE_UNAVAILABILITY_TYPE_OTHER,
  VEHICLE_UNAVAILABILITY_TYPES,
  VEHICLE_UNAVAILABILITY_TYPES_COLORS,
} from '../config/vehicleUnavailabilities'
import { isBookingEditable } from '../domain/bookings'
import { useResourcePermissions } from '../domain/permissions'
import { getResourceByName } from '../resources'
import bookingsConfig from '../resources/bookings/config'
import vehicleUnavailabilitiesConfig from '../resources/vehicleUnavailabilities/config'
import { toSentence } from '../utils'
import {
  formatDateTime,
  getDateBoundsWithOffsets,
  getMaxDate,
  getMinDate,
  getTodayDateBounds,
  getWeekDateBounds,
} from '../utils/dates'
import { getRandomColorFromString } from '../utils/theme'
import { defaultPresetName, defaultPresets } from '../vendor/bryntum'
import BryntumScheduler from '../vendor/bryntum/BryntumScheduler'

const getFilterFromDates = ({ startDate, endDate }) => ({
  timeline_start: startDate,
  timeline_end: endDate,
})

const getDatesFromEvent = (event) => {
  const isBooking = event.resource === bookingsConfig.name
  const startDate = new Date(isBooking ? event.effective_started_on : event.started_on)
  const endDate = new Date(isBooking ? event.effective_ended_on : event.ended_on)
  return { startDate, endDate }
}

const Scheduler = ({
  filter,
  groupAccessoryNameKey,
  groupCategories,
  groupCategoryKey,
  groupFilter,
  groupNameKey,
  groupResource,
  onBookingCreate,
  onBookingEdit,
  onVehicleUnavailabilityDelete,
  onVehicleUnavailabilityEdit,
  relatedResource,
}) => {
  const createPath = useCreatePath()
  const notify = useNotify()
  const translate = useTranslate()

  const { currentAccount } = useAccounts()
  const dataProvider = useDataProvider()
  const commonClasses = useCommonStyles()
  const scheduler = useRef()

  const [hasReadForBookings, hasEditForBookings] = useResourcePermissions(
    bookingsConfig.name,
    SYSTEM_PERMISSION_READ,
    SYSTEM_PERMISSION_UPDATE,
  )
  const [hasReadForVehicleUnavailabilities, hasEditForVehicleUnavailabilities, hasDeleteForVehicleUnavailabilities] =
    useResourcePermissions(
      vehicleUnavailabilitiesConfig.name,
      SYSTEM_PERMISSION_READ,
      SYSTEM_PERMISSION_UPDATE,
      SYSTEM_PERMISSION_DELETE,
    )

  const [defaultDates] = useState(getDateBoundsWithOffsets({ days: -14 }, { days: 14 }))
  const [defaultStartDate, defaultEndDate] = defaultDates
  const [dates, setDates] = useState({ startDate: defaultStartDate, endDate: defaultEndDate })
  const minStartDate = useRef(defaultStartDate)
  const maxEndDate = useRef(defaultEndDate)
  useEffect(() => {
    minStartDate.current = getMinDate(minStartDate.current, dates.startDate)
    maxEndDate.current = getMaxDate(maxEndDate.current, dates.endDate)
  }, [dates])

  const relatedConfig = getResourceByName(relatedResource)
  const groupConfig = getResourceByName(groupResource)
  const relatedRecordRepresentation = useGetRecordRepresentation(relatedConfig.name)
  const groupRecordRepresentation = useGetRecordRepresentation(groupConfig.name)

  const pagination = { page: 1, perPage: 99999 }
  const commonFilter = { ...filter, ...getFilterFromDates(dates) }

  let { data: bookings } = useGetList(
    bookingsConfig.name,
    { pagination, filter: { ...commonFilter, ...bookingsConfig.options.defaultFilterValues } },
    { enabled: hasReadForBookings },
  )
  bookings = bookings || []

  let { data: vehicleUnavailabilities } = useGetList(
    vehicleUnavailabilitiesConfig.name,
    { pagination, filter: commonFilter },
    { enabled: hasReadForVehicleUnavailabilities },
  )
  vehicleUnavailabilities = vehicleUnavailabilities || []

  const [fetchGroups, { data: rawGroups }] = useApi(`/` + groupResource, {
    method: 'GET',
    params: { filter: groupFilter, pagination },
  })

  const groups = useMemo(() => {
    return (rawGroups || []).map((r) => ({
      ...r,
      imageUrl: r.picture,
      name: groupRecordRepresentation(r),
      category: translate(get(groupCategories, get(r, groupCategoryKey), get(r, groupCategoryKey))),
    }))
  }, [rawGroups, translate]) // eslint-disable-line react-hooks/exhaustive-deps

  const resourceTimeRanges = useMemo(() => {
    const ranges = []
    groups.forEach((g) => {
      if (g.status?.severity === STATUS_SEVERITY_CRITICAL) {
        const codes = g.status.codes.map((c) => `"${translate(STATUS_CODES[c]).toLowerCase()}"`)
        const name =
          scheduler.current.schedulerEngine.zoomLevel > 1
            ? `${translate('resources.vehicles.criticalOperationalStatus')} ${toSentence(codes)}`
            : ''
        ranges.push({
          duration: 7,
          name,
          resourceId: g.id,
          startDate: new Date(),
          style:
            'justify-content: flex-start; align-items: center; color: #1F1F1F; font-size: 16px; font-weight: bold; padding-bottom: 0; padding-top: 0; padding-left: 0.75em; background: repeating-linear-gradient(315deg, #F5BBB7, #F5BBB7 5px, #E8EDEC 5px, #E8EDEC 10px);',
        })
      }
      if (g.ended_on) {
        ranges.push({
          duration: 9999,
          name: translate('resources.vehicles.outOfCurrentLease'),
          resourceId: g.id,
          startDate: new Date(g.ended_on),
          style:
            'justify-content: flex-start; align-items: center; color: #474747; font-size: 16px; font-weight: bold; padding-bottom: 0; padding-top: 0; padding-left: 0.75em; background: repeating-linear-gradient(315deg, #F0F0F0, #F0F0F0 5px, #D6D6D6 5px, #D6D6D6 10px);',
        })
      }
    })
    return ranges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, scheduler?.current?.schedulerEngine?.zoomLevel, translate])

  const groupCacheRef = useRef({})
  useEffect(() => {
    groupCacheRef.current = { ...groupCacheRef.current, ...keyBy(groups, 'id') }
  }, [groups])

  useEffect(() => {
    if (groupResource) {
      fetchGroups()
    }
  }, [JSON.stringify(groupFilter), fetchGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  const [hasReadForRelatedResource] = useResourcePermissions(relatedResource, SYSTEM_PERMISSION_READ)
  const relatedIds = useMemo(() => uniq(bookings.map((b) => b[relatedConfig.options.referenceKey])), [bookings]) // eslint-disable-line react-hooks/exhaustive-deps
  const { data: relatedItems } = useGetMany(
    relatedResource,
    { ids: relatedIds },
    { enabled: hasReadForRelatedResource },
  )
  const relatedCacheRef = useRef({})
  const relatedCache = useMemo(() => {
    relatedCacheRef.current = { ...relatedCacheRef.current, ...keyBy(relatedItems, 'id') }
    return relatedCacheRef.current
  }, [relatedItems])

  const schedule = useMemo(
    () =>
      [
        ...bookings.map((b) => ({ ...b, resource: bookingsConfig.name })),
        ...vehicleUnavailabilities.map((u) => ({ ...u, resource: vehicleUnavailabilitiesConfig.name })),
      ].map((event) => {
        const refKey = event[relatedConfig.options.referenceKey]
        const isBooking = event.resource === bookingsConfig.name
        const { startDate, endDate } = getDatesFromEvent(event)

        const name = isBooking
          ? relatedRecordRepresentation(relatedCache[refKey]).trim() || translate('resources.bookings.name', 1)
          : event.type === VEHICLE_UNAVAILABILITY_TYPE_OTHER
          ? translate('resources.maintenances.otherUnavailability')
          : translate(VEHICLE_UNAVAILABILITY_TYPES[event.type])
        const eventColor = isBooking
          ? getRandomColorFromString(refKey)
          : VEHICLE_UNAVAILABILITY_TYPES_COLORS[event.type]
        const icons = isBooking
          ? [get(BOOKING_BILLING_TYPES_ICONS, event.billing_type), getBookingConsumedIcon(event)]
          : [vehicleUnavailabilitiesConfig.icon]

        const link = createPath({ resource: event.resource, id: event.id, type: 'show' })
        const item = { ...event, name, startDate, endDate, eventColor, link, icons }
        if (groupResource) {
          item.resourceId = event[groupConfig.options.referenceKey]
        }
        return item
      }),
    [JSON.stringify({ bookings, vehicleUnavailabilities, relatedCache })], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onNavigate = useCallback((startDate, endDate) => setDates({ startDate, endDate }), [setDates])

  const canDeleteResource = useCallback(
    (resource) => {
      if (resource === bookingsConfig.name) return false
      if (resource === vehicleUnavailabilitiesConfig.name) return hasDeleteForVehicleUnavailabilities
      return false
    },
    [hasDeleteForVehicleUnavailabilities],
  )
  const canEditResource = useCallback(
    (data) => {
      if (data.resource === bookingsConfig.name) return hasEditForBookings && isBookingEditable(data)
      if (data.resource === vehicleUnavailabilitiesConfig.name) return hasEditForVehicleUnavailabilities
      return false
    },
    [hasEditForBookings, hasEditForVehicleUnavailabilities],
  )
  const canReadResource = useCallback(
    (resource) => {
      if (resource === bookingsConfig.name) return hasReadForBookings
      if (resource === vehicleUnavailabilitiesConfig.name) return hasReadForVehicleUnavailabilities
      return false
    },
    [hasReadForBookings, hasReadForVehicleUnavailabilities],
  )

  // We use a ref here to retrieve the updated value for the currentAccount object
  const onSeeEventItemPress = useRef()
  onSeeEventItemPress.current = (eventRecord) => {
    if (!currentAccount) return
    const { resource, id } = eventRecord.data
    const link = createPath({ resource, id, type: 'show' })
    window.open('/' + currentAccount.slug + link, '_blank')
  }

  const features = useMemo(() => {
    const feats = {
      regionResize: false,
      timeRanges: { showCurrentTimeLine: true },
      resourceTimeRanges: true,
      stripe: true,
      sort: 'name',

      eventTooltip: {
        template: (data) => {
          const resourceRecord = get(groupCacheRef.current, data.eventRecord.get(groupConfig.options.referenceKey))
          const isBooking = data.eventRecord.get('resource') === bookingsConfig.name
          const icons = data.eventRecord.get('icons')
          return renderToString(
            <>
              <div class="b-sch-event-tooltip-icon">
                {icons.map((Icon) => (
                  <Icon key={Icon.displayName} />
                ))}
              </div>
              <div class="b-sch-event-tooltip-header">
                <div class="b-sch-event-tooltip-title">{data.eventRecord.get('name')}</div>
                {resourceRecord && <div class="b-sch-event-tooltip-subtitle">{resourceRecord.name}</div>}
              </div>
              <dl>
                {isBooking && (
                  <>
                    <dt>{translate('mymove.scheduler.scheduledOn')}</dt>
                    <dd>
                      {formatDateTime(data.eventRecord.get('start_scheduled_on'))} →{' '}
                      {formatDateTime(data.eventRecord.get('end_scheduled_on'))}
                    </dd>
                  </>
                )}
                <dt>{translate(isBooking ? 'mymove.scheduler.usedOn' : 'mymove.scheduler.period')}</dt>
                <dd>
                  {formatDateTime(data.eventRecord.get('started_on'))} →{' '}
                  {formatDateTime(data.eventRecord.get('ended_on'))}
                </dd>
              </dl>
            </>,
          )
        },
      },

      eventContextMenu: {
        items: { unassignEvent: false },
        processItems: ({ eventRecord, items }) => {
          // "Edit", "Delete" & "Show" buttons when right-clicking on an event
          const { resource } = eventRecord.data
          const itemName = translate(`resources.${resource}.name`, 1).toLowerCase()
          const canDelete = canDeleteResource(resource)
          const canEdit = canEditResource(eventRecord.data)
          const canRead = canReadResource(resource)

          if (canDelete) {
            items.deleteEvent = {
              text: translate('ra.action.delete') + ' ' + itemName,
              icon: 'b-fa b-fa-trash',
              onItem: ({ eventRecord }) => {
                const { id, resource } = eventRecord.data
                if (resource === vehicleUnavailabilitiesConfig.name) {
                  onVehicleUnavailabilityDelete(id)
                }
              },
            }
          } else {
            items.deleteEvent = false
          }

          if (canEdit) {
            items.editEvent.text = translate('ra.action.edit') + ' ' + itemName
          } else {
            items.editEvent = false
          }

          if (canRead) {
            items.seeEvent = {
              text: translate('ra.action.show') + ' ' + itemName,
              icon: 'b-fa b-fa-eye',
              onItem: ({ eventRecord }) => onSeeEventItemPress.current(eventRecord),
            }
          } else {
            items.seeEvent = false
          }
        },
      },

      eventDrag: {
        showExactDropPosition: true,
        constrainDragToResource: false,
        validatorFn: ({ startDate, record, resourceRecord, newResource }) => {
          const { resource } = record.data
          // Prevent a booking from being moved in the past
          if (resource === bookingsConfig.name && startDate < Date.now()) {
            return false
          }
          // Prevent updating the vehicle of a vehicle unavailability
          if (resource === vehicleUnavailabilitiesConfig.name && resourceRecord.data.id !== newResource.data.id) {
            return false
          }
          return true
        },
      },

      eventResize: {
        showExactResizePosition: true,
        validatorFn: ({ startDate, endDate, eventRecord, edge }) => {
          const { resource, startDate: originalStartDate } = eventRecord.data
          if (resource === bookingsConfig.name) {
            // Prevent resizing a finished booking
            if (endDate < Date.now()) {
              return false
            }
            // Prevent resizing a current booking start date
            if (edge === 'left' && originalStartDate < Date.now()) {
              return false
            }
            // Prevent resizing a future booking into the past
            if (edge === 'left' && startDate < Date.now()) {
              return false
            }
          }
          return true
        },
      },

      eventDragCreate: {
        validatorFn: ({ resourceRecord, startDate, endDate }) => {
          // Prevent creating anything before the vehicle is available
          if (startDate < new Date(resourceRecord.data.started_on)) {
            return false
          }
          // Prevent creating anything after the vehicle becomes unavailable
          if (resourceRecord.data.ended_on && startDate > new Date(resourceRecord.data.ended_on)) {
            return false
          }
          // Prevent creating anything ending after the vehicle becomes unavailable
          if (resourceRecord.data.ended_on && endDate > new Date(resourceRecord.data.ended_on)) {
            return false
          }
          // Prevent creating anything in the past
          if (startDate < Date.now()) {
            return false
          }
          return true
        },
      },

      scheduleContextMenu: { items: { addEvent: false } },
    }

    if (groupCategories) {
      feats.group = 'category'
    }

    return feats
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canDeleteResource, canEditResource, canReadResource])

  const listeners = useMemo(() => {
    return {
      timeAxisChange: ({ config: { startDate, endDate } }) => {
        onNavigate(startDate, endDate)
      },
      beforeEventDrag: ({ eventRecord }) => {
        const { data, startDate } = eventRecord
        if (!canEditResource(data)) {
          return false
        }
        // Prevent moving a booking that has already started
        if (data.resource === bookingsConfig.name && startDate < Date.now()) {
          return false
        }
        return true
      },
      beforeEventResize: ({ eventRecord }) => {
        const { data, endDate } = eventRecord
        if (!canEditResource(data)) {
          return false
        }
        // Prevent resizing a finished booking
        if (data.resource === bookingsConfig.name && endDate < Date.now()) {
          return false
        }
        return true
      },
      beforeEventDropFinalize: async ({ context }) => {
        context.async = true
        const result = window.confirm(translate('mymove.scheduler.moveElementConfirmation'))
        context.finalize(result)
      },
      beforeEventEdit: ({ eventRecord, resourceRecord }) => {
        const { isPhantom, startDate, endDate } = eventRecord
        if (isPhantom) {
          if (startDate < Date.now()) {
            return false
          }
          onBookingCreate({
            vehicle_id: groupResource === 'vehicles' ? resourceRecord.get('id') : null,
            start_scheduled_on: startDate,
            end_scheduled_on: endDate,
          })
        } else {
          const { resource } = eventRecord.data
          const canEdit = canEditResource(eventRecord.data)
          if (resource === bookingsConfig.name && canEdit) {
            onBookingEdit(eventRecord.data)
          } else if (resource === vehicleUnavailabilitiesConfig.name && canEdit) {
            onVehicleUnavailabilityEdit(eventRecord.data)
          }
        }
        return false
      },
    }
  }, [canEditResource]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() => {
    return [
      {
        text: 'Category',
        field: 'category',
        hidden: true,
      },
      {
        type: 'resourceInfo',
        showImage: true,
        text: groupConfig.options.singleLabel,
        field: 'name',
        width: 240,
        autoScaleThreshold: 0,
        renderer: (data) => {
          return renderToString(
            <CardHeader
              style={{ padding: 0 }}
              avatar={
                data.record.picture ? (
                  <Box width={60} height={60} style={{ position: 'relative' }}>
                    <img
                      className={classnames(commonClasses.absoluteFill, commonClasses.objectContain)}
                      alt={data.record.name}
                      src={data.record.picture}
                    />
                  </Box>
                ) : (
                  <Avatar alt={data.record.name}>{(data.record.name || ' ').charAt(0)}</Avatar>
                )
              }
              title={
                typeof groupNameKey === 'function' ? groupNameKey(data.record) : groupRecordRepresentation(data.record)
              }
              subheader={
                typeof groupAccessoryNameKey === 'function'
                  ? groupAccessoryNameKey(data.record)
                  : data.record.get(groupAccessoryNameKey)
              }
            />,
          )
        },
      },
    ]
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const eventStore = useMemo(() => {
    return {
      onUpdate: ({ record, changes: { resourceId, startDate, endDate, failedUpdate } }) => {
        if (failedUpdate?.value === true) {
          record.set('failedUpdate', false, true)
          return true
        }
        if (!Boolean(resourceId) && !Boolean(startDate) && !Boolean(endDate)) return true

        const { resource, id } = record.data
        const isBooking = resource === bookingsConfig.name
        const startKey = isBooking ? 'start_scheduled_on' : 'started_on'
        const endKey = isBooking ? 'end_scheduled_on' : 'ended_on'

        const attributes = isBooking
          ? ['justification', 'billing_type', 'additional_driver_user_ids']
          : ['organisation_id', 'hub_id']

        const updatedValues = {
          ...Object.fromEntries([...attributes, 'vehicle_id'].map((attr) => [attr, record.data[attr]])),
          [startKey]: startDate?.value?.toISOString() || record.data[startKey],
          [endKey]: endDate?.value?.toISOString() || record.data[endKey],
          ...(resourceId?.value && { vehicle_id: resourceId.value }),
        }
        record.set(updatedValues, null, true)
        dataProvider.update(resource, { id, data: updatedValues }).catch((error) => {
          record.set({
            ...(startDate && { startDate: startDate.oldValue, [startKey]: startDate.oldValue.toISOString() }),
            ...(endDate && { endDate: endDate.oldValue, [endKey]: endDate.oldValue.toISOString() }),
            ...(resourceId && { vehicle_id: resourceId.oldValue, resourceId: resourceId.oldValue }),
            failedUpdate: true,
          })
          notify(error.message, { type: 'error' })
        })
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Fix bug where permissions are not yet loaded, causing the scheduler to initially render with wrong settings
  const permissions = [
    hasReadForBookings,
    hasEditForBookings,
    hasReadForVehicleUnavailabilities,
    hasEditForVehicleUnavailabilities,
    hasDeleteForVehicleUnavailabilities,
  ]
  if (permissions.some((p) => p === undefined)) {
    return null
  }

  return (
    <div>
      <Box className={commonClasses.borderBottom} flex={1} display="flex" justifyContent="flex-end">
        <Box p={2} display="flex">
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomTo({
                    preset: 'hourAndDayCustom',
                    startDate: getTodayDateBounds()[0],
                    endDate: getTodayDateBounds()[1],
                  })
                }}
              >
                {translate('mymove.dates.today')}
              </Button>
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomToSpan({
                    startDate: getWeekDateBounds()[0],
                    endDate: getWeekDateBounds()[1],
                  })
                }}
              >
                {translate('mymove.dates.week')}
              </Button>
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomTo({
                    preset: 'weekAndMonthCustom',
                  })
                }}
              >
                {translate('mymove.dates.month')}
              </Button>
            </ButtonGroup>
          </Box>
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button
                disabled={
                  scheduler.current &&
                  scheduler.current.schedulerEngine.zoomLevel <= scheduler.current.schedulerEngine.minZoomLevel
                }
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomOut()
                }}
              >
                <MinusIcon />
              </Button>
              <Button
                disabled={
                  scheduler.current &&
                  scheduler.current.schedulerEngine.zoomLevel >= scheduler.current.schedulerEngine.maxZoomLevel
                }
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomIn()
                }}
              >
                <PlusIcon />
              </Button>
            </ButtonGroup>
          </Box>
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button onClick={() => scheduler.current.schedulerEngine.shiftPrevious()}>
                <PreviousIcon />
              </Button>
              <Button onClick={() => scheduler.current.schedulerEngine.shiftNext()}>
                <NextIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <BryntumScheduler
        ref={scheduler}
        autoHeight
        rowHeight={60}
        presets={defaultPresets}
        viewPreset={defaultPresetName}
        useInitialAnimation={false}
        allowOverlap={false}
        snap
        eventRenderer={({ eventRecord }) => ({
          headerText: eventRecord.name,
          footerText: formatDateTime(eventRecord.startDate) + ' → ' + formatDateTime(eventRecord.endDate),
        })}
        eventBodyTemplate={(data) => `
          <section class="b-sch-event-body">
            <div class="b-sch-event-header">${data.headerText}</div>
            <div>${data.footerText}</div>
          </section>
        `}
        resources={groups}
        resourceTimeRanges={resourceTimeRanges}
        events={schedule}
        features={features}
        columns={columns}
        listeners={listeners}
        eventStore={eventStore}
      />
    </div>
  )
}

export default Scheduler

import config from './config'
import HubFormLayout, { transformValues } from './form'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditButton } from '../common/edit'

export const CreateHubButton = ({ organisationId }) => (
  <AdvancedCreateButton
    defaultValues={{ organisation_id: organisationId }}
    isFloatingOnSmallScreen={false}
    label="resources.hubs.actions.create.name"
    resource={config.name}
    transform={transformValues}
  >
    <HubFormLayout />
  </AdvancedCreateButton>
)

export const EditHubButton = ({ id }) => (
  <AdvancedEditButton id={id} label="resources.hubs.actions.update.name" transform={transformValues}>
    <HubFormLayout />
  </AdvancedEditButton>
)

import { SelectInput, useResourceContext } from 'react-admin'

const AdvancedBooleanFilter = ({ source, ...props }) => {
  const resource = useResourceContext()

  const translationPrefix = `resources.${resource}.filters.${source}`

  const choices = [false, true].map((value) => ({ id: value, name: translationPrefix + `.values.${value}` }))
  return <SelectInput choices={choices} label={translationPrefix + `.name`} source={source} {...props} />
}

export default AdvancedBooleanFilter

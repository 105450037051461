import { FunctionField, ReferenceField } from 'react-admin'

import config from './config'
import { VOUCHER_DISCOUNT_TYPE_MINUTE, VOUCHER_DISCOUNT_TYPE_PERCENTAGE } from '../../config/vouchers'
import { formatDuration } from '../../utils/dates'

export const DiscountField = (props) => (
  <FunctionField
    render={(record) => {
      if (!record) {
        return null
      }
      switch (record.discount_type) {
        case VOUCHER_DISCOUNT_TYPE_PERCENTAGE:
          return `${record.discount_value}%`
        case VOUCHER_DISCOUNT_TYPE_MINUTE:
          return formatDuration(record.discount_value)
        default:
          return record.discount_value
      }
    }}
    {...props}
  />
)

DiscountField.defaultProps = {
  label: 'resources.vouchers.fields.discount',
}

const VoucherField = (props) => <ReferenceField {...props} />

VoucherField.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  emptyText: 'n/a',
  link: 'show',
}

export default VoucherField

import { SelectInput } from 'react-admin'

import { BOOKING_ERAS } from '../../config/bookings'

const bookingPeriodChoices = Object.entries(BOOKING_ERAS).map(([k, v]) => ({ id: k, name: v }))
export const BookingPeriodFilter = (props) => <SelectInput {...props} choices={bookingPeriodChoices} />
BookingPeriodFilter.defaultProps = {
  source: 'era',
  label: 'resources.bookings.filters.era.name',
}

import { useEffect } from 'react'
import { useLocaleState } from 'react-admin'

import config from './config'
import { useApi } from '../../api/apiProvider'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

export const useGetBookingsJustifications = () => {
  const [locale] = useLocaleState()
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const [fetchBookingsJustifications, { data: bookingsJustifications }] = useApi('/bookings/justifications', {
    method: 'GET',
    params: { locale },
  })
  useEffect(() => {
    if (hasRead) fetchBookingsJustifications()
  }, [fetchBookingsJustifications, hasRead])
  return bookingsJustifications
}

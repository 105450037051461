import { pick } from 'lodash'
import { useEffect } from 'react'
import { required, SelectInput } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { ACCOUNT_BILLING_TYPES_SOURCE, ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE } from '../config/accounts'
import { BOOKING_BILLING_TYPES } from '../config/bookings'

const DefaultBillingTypeSelectInput = ({
  source = ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
  billingTypesSource = ACCOUNT_BILLING_TYPES_SOURCE,
  isRequired = true,
  ...props
}) => {
  const { setValue } = useFormContext()
  const [billingTypes, defaultBillingType] = useWatch({ name: [billingTypesSource, source] })
  const filteredBillingTypes = pick(BOOKING_BILLING_TYPES, billingTypes)
  const choices = Object.entries(filteredBillingTypes).map(([id, name]) => ({ id, name }))

  useEffect(() => {
    if (billingTypes && !billingTypes.includes(defaultBillingType)) {
      setValue(source, billingTypes[0])
    }
  }, [JSON.stringify(billingTypes), setValue, source]) // eslint-disable-line react-hooks/exhaustive-deps

  return <SelectInput {...props} source={source} choices={choices} validate={isRequired && required()} />
}

export default DefaultBillingTypeSelectInput

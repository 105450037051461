import { Box, InputAdornment, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo } from 'react'
import {
  CREATE,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  useGetOne,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { useGetTransactionsListForBooking } from './hooks'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import PriceField from '../../components/PriceField'
import { BOOKING_TRANSACTION_FORM_TYPES } from '../../config/bookingTransactions'
import env from '../../config/env'
import { useCommonStyles } from '../../config/theme'
import {
  getHumanisedBookingTransactionType,
  isBookingTransactionTypeRefund,
  isBookingTransactionTypeReleasePreAuth,
} from '../../domain/bookingTransactions'
import { formatDateTime } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import bookingDetailsConfig from '../bookingDetails/config'
import { FormDivider } from '../common'
import { AdvancedSimpleForm } from '../common/forms'

export const transformValues = (values) => ({
  ...values,
  charge_transaction_id: isBookingTransactionTypeRefund(values) ? values.charge_transaction_id : null,
  amount: isBookingTransactionTypeReleasePreAuth(values) ? 0 : values.amount,
})

const AmountNumberInput = ({ maxRefundableAmount, ...props }) => {
  const source = 'amount'
  const { setValue } = useFormContext()
  const values = useWatch()

  const validateAmount = [
    required(),
    number('mymove.validation.number.invalid'),
    minValue(1 * 100, 'mymove.validation.number.positive'),
    isBookingTransactionTypeRefund(values)
      ? maxValue(maxRefundableAmount, 'resources.booking-transactions.forms.validation.amount.greaterThanCharge')
      : false,
  ]

  useEffect(() => {
    if (isBookingTransactionTypeRefund(values)) {
      setValue(source, maxRefundableAmount)
    } else {
      setValue(source, null)
    }
  }, [setValue, source, values.type, maxRefundableAmount]) // eslint-disable-line react-hooks/exhaustive-deps

  return Boolean(values.type) && !isBookingTransactionTypeReleasePreAuth(values) ? (
    <NumberInput
      {...props}
      source={source}
      validate={validateAmount}
      readOnly={isBookingTransactionTypeRefund(values) && !Boolean(values.charge_transaction_id)}
      format={(v) => (v ? v / 100 : null)}
      parse={(v) => v * 100}
      min={1}
      max={isBookingTransactionTypeRefund(values) ? maxRefundableAmount / 100 : null}
      InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
    />
  ) : null
}

const BookingTransactionFormLayout = ({ defaultValues }) => {
  const bookingId = defaultValues?.booking_id
  const { data: bookingDetails } = useGetOne(
    bookingDetailsConfig.name,
    { id: bookingId },
    { enabled: Boolean(bookingId) },
  )
  const { data: bookingTransactions } = useGetTransactionsListForBooking(bookingId)

  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()

  const bookingTransactionTypeChoices = useMemo(
    () =>
      Object.entries(BOOKING_TRANSACTION_FORM_TYPES).map(([k, v]) => ({
        id: k,
        name: v,
        disabled: !bookingDetails?.allowed_transaction_actions.includes(k),
      })),
    [JSON.stringify(bookingDetails?.allowed_transaction_actions)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const bookingRefundableTransactionsChoices = useMemo(
    () =>
      bookingTransactions
        ? bookingTransactions
            .filter((t) => t.refundable_amount > 0)
            .map((t) => ({
              id: t.id,
              name: `${translate(getHumanisedBookingTransactionType(t))} - ${formatDateTime(t.created_on)}`,
            }))
        : [],
    [JSON.stringify(bookingTransactions)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (!Boolean(bookingId) || isEmpty(bookingDetails?.allowed_transaction_actions) || isEmpty(bookingTransactions)) {
    return null
  }

  return (
    <AdvancedSimpleForm defaultValues={defaultValues} toolbar={<BasicFormToolbar formType={CREATE} />} type={CREATE}>
      <SelectInput source="type" choices={bookingTransactionTypeChoices} validate={required()} />
      <FormDataConsumer>
        {({ formData }) =>
          isBookingTransactionTypeRefund(formData) && (
            <SelectInput
              source="charge_transaction_id"
              choices={bookingRefundableTransactionsChoices}
              validate={required()}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <AmountNumberInput
            maxRefundableAmount={
              Boolean(formData.charge_transaction_id)
                ? bookingTransactions.find((t) => t.id === formData.charge_transaction_id).refundable_amount
                : null
            }
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          isBookingTransactionTypeReleasePreAuth(formData) && (
            <Box sx={{ marginLeft: '22px' }}>
              <Typography variant="body2" component="span">
                {translate('resources.booking-transactions.forms.releasedAmountText')}
                <PriceField record={bookingDetails} source="pa_available_amount" />
              </Typography>
            </Box>
          )
        }
      </FormDataConsumer>
      <FormDivider />
      <FormDataConsumer>
        {({ formData }) => (
          <TextInput
            source="justification"
            validate={Boolean(formData.type) && !isBookingTransactionTypeReleasePreAuth(formData) ? required() : null}
            className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
          />
        )}
      </FormDataConsumer>
    </AdvancedSimpleForm>
  )
}

export default BookingTransactionFormLayout

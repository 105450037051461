import get from 'lodash/get'

export const ERROR_MESSAGES = {
  active_ops_user: 'mymove.errors.messages.activeOpsUser',
  active_vehicle: 'mymove.errors.messages.activeVehicle',
  already_archived: 'mymove.errors.messages.alreadyArchived',
  already_booked: 'mymove.errors.messages.alreadyBooked',
  already_cancelled: 'mymove.errors.messages.alreadyCancelled',
  booking_already_ended: 'mymove.errors.messages.bookingAlreadyEnded',
  booking_already_started: 'mymove.errors.messages.bookingAlreadyStarted',
  booking_extension_error: 'mymove.errors.messages.bookingExtensionError',
  booking_not_started_yet: 'mymove.errors.messages.bookingNotStartedYet',
  booking_restriction: 'mymove.errors.messages.bookingRestriction',
  booking_restriction_missing_additional_document: 'mymove.errors.messages.bookingRestrictionMissingAdditionalDocument',
  booking_restriction_missing_payment_method: 'mymove.errors.messages.bookingRestrictionMissingPaymentMethod',
  booking_restriction_missing_required_document: 'mymove.errors.messages.bookingRestrictionMissingRequiredDocument',
  cannot_double_book: 'mymove.errors.messages.cannotDoubleBook',
  current_booking_update_error: 'mymove.errors.messages.currentBookingUpdateError',
  email_duplicate: 'mymove.errors.messages.emailDuplicate',
  email_invalid: 'mymove.errors.messages.emailInvalid',
  invalid_billing_type: 'mymove.errors.messages.invalidBillingType',
  invalid_booking: 'mymove.errors.messages.invalidBooking',
  invalid_booking_dates: 'mymove.errors.messages.invalidBookingDates',
  invalid_code: 'mymove.errors.messages.invalidCode',
  invalid_date: 'mymove.errors.messages.invalidDate',
  invalid_price_factor: 'mymove.errors.messages.invalidPriceFactor',
  invalid_price_per_km: 'mymove.errors.messages.invalidPricePerKm',
  invalid_charging_buffer: 'mymove.errors.messages.invalidChargingBuffer',
  maintenance_prevents_booking: 'mymove.errors.messages.maintenancePreventsBooking', // TODO later in sync with backend: modify error code
  missing_parking_spot: 'mymove.errors.messages.missingParkingSpot',
  no_booking_found: 'mymove.errors.messages.noBookingFound',
  not_available: 'mymove.errors.messages.notAvailable',
  not_in_zone: 'mymove.errors.messages.notInZone',
  organisation_id_not_found: 'mymove.errors.messages.organisationIdNotFound',
  organisation_not_found: 'mymove.errors.messages.organisationNotFound',
  orphan_booking: 'mymove.errors.messages.orphanBooking',
  past_booking_update_error: 'mymove.errors.messages.pastBookingUpdateError',
  phone_number_duplicate: 'mymove.errors.messages.phoneNumberDuplicate',
  role_inconsistent: 'mymove.errors.messages.roleInconsistent',
  user_not_found: 'mymove.errors.messages.userNotFound',
  vehicle_maintenance_conflict: 'mymove.errors.messages.vehicleMaintenanceConflict', // TODO later in sync with backend: modify error code
  vehicle_not_bookable_in_advance: 'mymove.errors.messages.vehicleNotBookableInAdvance',
  vehicle_not_found: 'mymove.errors.messages.vehicleNotFound',
}

export const getErrorDataFromError = (error) =>
  get(error, 'body.errors[0]') ?? get(error, 'body.error') ?? get(error, 'body.detail')

const parseError = (error) => {
  const errorData = getErrorDataFromError(error)
  if (errorData) {
    if (typeof errorData === 'string') {
      return new Error(errorData)
    }
    if (typeof errorData === 'object') {
      const { code, message } = errorData
      return new Error(get(ERROR_MESSAGES, code, `${message} (code: ${code})`))
    }
  }
  return error
}

export const errorParser =
  (func) =>
  (...args) =>
    func(...args).catch((error) => {
      throw parseError(error)
    })

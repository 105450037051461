import { Typography } from '@mui/material'
import React from 'react'
import { Filter, List, useResourceContext } from 'react-admin'

import { CreateVehicleUnavailabilityButton, DeleteVehicleUnavailabilityButton } from './buttons'
import config from './config'
import VehicleUnavailabilityFormLayout, { transformValues } from './form'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import { SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'
import VehicleField from '../vehicles/field'
import VehiclesFilter from '../vehicles/filter'

const VehicleUnavailabilitiesFilters = (props) => (
  <Filter {...props}>
    <OrganisationsFilter alwaysOn />
    <VehiclesFilter alwaysOn />
  </Filter>
)

export const VehicleUnavailabilitiesListLayout = ({ createProps, excluded = [], ...props }) => {
  const commonClasses = useCommonStyles()
  const { hasSingleOrganisation } = useCurrentAccountSelectors()
  const resource = useResourceContext()
  const [hasDelete] = useResourcePermissions(resource, SYSTEM_PERMISSION_DELETE)

  return (
    <GenericListLayout
      action={createProps && <CreateVehicleUnavailabilityButton {...createProps} />}
      compactListLayout={
        <CompactList
          linkType="show"
          icon={<config.icon />}
          title={() => (
            <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
              <ReferenceValue reference="organisations" />
              {' • '}
              <ReferenceValue reference="vehicles" />
            </Typography>
          )}
          body={() => (
            <>
              <StaticText>{'resources.maintenances.fields.type'}: </StaticText>
              <AdvancedTextField source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
              <br />
              <PeriodField startedOnSource="started_on" endedOnSource="ended_on" addTime />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid>
          {isAllowed(excluded, 'organisations') && !hasSingleOrganisation && <OrganisationField sortable={false} />}
          {isAllowed(excluded, 'vehicles') && <VehicleField />}
          <AdvancedTextField source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
          <DateTooltipField source="started_on" addTime />
          <DateTooltipField source="ended_on" addTime />
          <AdvancedEditInDialogButton transform={transformValues}>
            <VehicleUnavailabilityFormLayout />
          </AdvancedEditInDialogButton>
          {hasDelete && <DeleteVehicleUnavailabilityButton />}
        </ListDatagrid>
      }
      {...props}
    />
  )
}

export default () => {
  return (
    <List
      empty={false}
      sort={config.options.defaultSort}
      filters={<VehicleUnavailabilitiesFilters />}
      actions={
        <ListActions>
          <AdvancedCreateButton transform={transformValues}>
            <VehicleUnavailabilityFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <VehicleUnavailabilitiesListLayout />
    </List>
  )
}

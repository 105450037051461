import { buildI18nProvider } from '@react-admin/ra-enterprise'
import { resolveBrowserLocale } from 'react-admin'

import en from './locales/en'
import fr from './locales/fr'
import nl from './locales/nl'

const translations = { en, fr, nl }

export default buildI18nProvider(
  translations,
  resolveBrowserLocale('en'),
  {},
  {
    allowMissing: true,
    onMissingKey: (key, _, __) => key,
  },
)

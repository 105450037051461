import { ReferenceField } from 'react-admin'

import config from './config'
import AdvancedTextField from '../../components/AdvancedTextField'

const OrganisationField = ({ variant, ...props }) => (
  <ReferenceField {...props}>
    <AdvancedTextField source="name" />
  </ReferenceField>
)

OrganisationField.defaultProps = {
  source: config.options.referenceKey,
  reference: config.name,
  link: 'show',
  fallback: '',
}

export default OrganisationField

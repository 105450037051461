import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { useRecordContext } from 'react-admin'

import StaticText from './StaticText'
import { useShowStyles } from '../resources/common/show'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

const UserNFCCardsField = () => {
  const { nfc_cards: nfcCards } = useRecordContext()
  const showClasses = useShowStyles()
  const classes = useStyles()

  if (isEmpty(nfcCards)) {
    return <StaticText>n/a</StaticText>
  }
  return (
    <ul className={showClasses.unorderedList}>
      {nfcCards.map(({ card_id: cardId, nfc_type: nfcType }) => (
        <li key={cardId} className={classnames(showClasses.unorderedListItem, classes.unorderedListItem)}>
          <p>
            {cardId} ({nfcType})
          </p>
        </li>
      ))}
    </ul>
  )
}

UserNFCCardsField.defaultProps = {
  label: 'resources.users.fields.nfc_cards.name',
}

export default UserNFCCardsField

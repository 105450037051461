import EventNoteIcon from '@mui/icons-material/EventNote'
import ListIcon from '@mui/icons-material/List'
import { Box } from '@mui/material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export const viewModeTimeline = 'timeline'
export const viewModeList = 'list'

const ViewModeToggle = ({ mode, onChange }) => {
  return (
    <Box flex={1} display="flex" justifyContent="flex-end">
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={(event, value) => {
          value && onChange(value)
        }}
        size="small"
      >
        <ToggleButton value={viewModeList}>
          <ListIcon />
        </ToggleButton>
        <ToggleButton value={viewModeTimeline}>
          <EventNoteIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ViewModeToggle

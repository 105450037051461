import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { get, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { useTranslate } from 'ra-core'
import { Fragment, useState } from 'react'
import { useNotify, useResourceContext } from 'react-admin'
import { Link } from 'react-router-dom'

import SensitiveDataAccessDialog from './SensitiveDataAccessDialog'
import StaticText from './StaticText'
import { useApi } from '../api/apiProvider'
import { getErrorDataFromError } from '../config/errors'
import { SYSTEM_PERMISSION_PRIVATE_DATA } from '../config/permissions'
import { useCommonStyles } from '../config/theme'
import {
  USER_DOCUMENT_STATUS_ACCEPTED,
  USER_DOCUMENT_STATUS_MISSING,
  USER_DOCUMENT_STATUS_PENDING,
  USER_DOCUMENT_STATUSES,
  USER_DOCUMENT_STATUSES_ICONS,
  USER_DOCUMENT_TYPE_IDENTIFICATION,
  USER_DOCUMENT_TYPES,
  USER_IDENTIFICATION_DATA_KEY_DATE_OF_BIRTH,
  USER_IDENTIFICATION_DATA_LABELS,
} from '../config/users'
import { useResourcePermissions } from '../domain/permissions'
import { useShowStyles } from '../resources/common/show'
import usersConfig from '../resources/users/config'
import { parseDateAsDateTime } from '../utils/dates'

const useStyles = makeStyles((theme) => ({
  smallIcon: {
    verticalAlign: 'sub',
    height: 20,
    marginTop: -1,
  },
  largeIcon: {
    verticalAlign: 'bottom',
    height: 24,
    marginTop: -4,
  },
  unorderedList: {
    marginBottom: theme.spacing(1),
  },
}))

const IdentificationDataDialog = ({ isOpen, onClose, userId }) => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [justification, setJustification] = useState('')

  const classes = useStyles()
  const showClasses = useShowStyles()
  const resource = useResourceContext()
  const notify = useNotify()
  const translate = useTranslate()

  const translationPrefix = `resources.${resource}.show.identificationData`

  const [fetchIdentificationData] = useApi(`/${resource}/${userId}/private-data`, {
    method: 'POST',
    body: JSON.stringify({ justification }),
    onSuccess: (response) => {
      setData(response.json)
      setTimeout(() => setIsLoading(false), 200)
    },
    onFailure: (error) => {
      setIsLoading(false)
      const { message } = getErrorDataFromError(error)
      notify(translate('mymove.errors.errorWithMessage', { message }), { type: 'error' })
    },
  })

  const handleConfirm = () => {
    if (!justification || isLoading) return
    setIsLoading(true)
    fetchIdentificationData()
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setData({})
      setIsLoading(false)
      setJustification('')
    }, 200)
  }

  return (
    <>
      <SensitiveDataAccessDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        isConfirmDisabled={!justification}
        isOpen={isOpen && isEmpty(data)}
        loading={isLoading}
        onJustificationChange={(event) => setJustification(event.target.value)}
        translationPrefix={translationPrefix}
      />
      <Dialog open={isOpen && !isEmpty(data)} onClose={handleClose}>
        <DialogTitle>{translate(`${translationPrefix}.title`)}</DialogTitle>
        <DialogContent sx={{ paddingBottom: '12px' }}>
          <ul className={classnames(classes.unorderedList, showClasses.unorderedList)}>
            {Object.entries(data).map(([key, value]) => {
              if (key === 'document_type') return null
              return (
                <li key={key} className={showClasses.unorderedListItem}>
                  <Typography component="span">
                    {translate(USER_IDENTIFICATION_DATA_LABELS[key])}:{' '}
                    {key === USER_IDENTIFICATION_DATA_KEY_DATE_OF_BIRTH
                      ? parseDateAsDateTime(value).toLocaleString(DateTime.DATE_SHORT)
                      : value}
                  </Typography>
                </li>
              )
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate(`${translationPrefix}.close`)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UserDocumentStatusField = ({ record }) => {
  const translate = useTranslate()
  const [hasAccessToPrivateData] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_PRIVATE_DATA)
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const [isIdentificationDataDialogOpen, setIsIdentificationDataDialogOpen] = useState(false)
  const closeDialog = () => setIsIdentificationDataDialogOpen(false)

  const documents = get(record, 'documents', [])

  if (record.is_trusted) {
    return <StaticText>resources.users.show.trustedDocumentNotRequired</StaticText>
  }

  return Object.keys(USER_DOCUMENT_TYPES).map((documentType) => {
    const document = documents.find((doc) => doc.type === documentType)
    const { status, type } = document
    const Icon = USER_DOCUMENT_STATUSES_ICONS[status]
    const shouldEnableIdentificationLink =
      hasAccessToPrivateData && type === USER_DOCUMENT_TYPE_IDENTIFICATION && status === USER_DOCUMENT_STATUS_ACCEPTED

    const documentTypeLabel = translate(USER_DOCUMENT_TYPES[type])
    return (
      <Fragment key={type}>
        <Tooltip title={translate(USER_DOCUMENT_STATUSES[status])}>
          <Icon
            className={
              status === USER_DOCUMENT_STATUS_MISSING || status === USER_DOCUMENT_STATUS_PENDING
                ? classes.smallIcon
                : classes.largeIcon
            }
          />
        </Tooltip>
        {'\xa0'}
        {shouldEnableIdentificationLink ? (
          <>
            <Typography component="span" variant="body2" color="primary">
              <Link to="." onClick={() => setIsIdentificationDataDialogOpen(true)} className={commonClasses.link}>
                {documentTypeLabel}
              </Link>
            </Typography>
            <IdentificationDataDialog
              isOpen={isIdentificationDataDialogOpen}
              onClose={closeDialog}
              userId={record.id}
            />
          </>
        ) : (
          <StaticText>{documentTypeLabel}</StaticText>
        )}
        {'\xa0\xa0\xa0\xa0'}
      </Fragment>
    )
  })
}

UserDocumentStatusField.defaultProps = {
  label: 'resources.users.enums.documentStatus.name',
}

export default UserDocumentStatusField

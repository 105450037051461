import { alpha } from '@mui/material/styles'
import { useTranslate } from 'ra-core'
import { Filter, List, useGetIdentity, useResourceContext, WrapperField } from 'react-admin'

import { DeleteOpsUserButton } from './buttons'
import config from './config'
import OpsUserFormLayout from './form'
import AdvancedBooleanFilter from '../../components/AdvancedBooleanFilter'
import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import { getUserFullName } from '../../components/UserFullNameField'
import { OPS_USER_ROLES_NAMES, SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { darkTheme } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { AdvancedCreateButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import { GenericListLayout, ListActions, ListDatagrid } from '../common/list'
import OrganisationField from '../organisations/field'

const OpsUsersFilters = (props) => {
  return (
    <Filter {...props}>
      <AdvancedSearchFilter alwaysOn />
      <AdvancedBooleanFilter source="active" alwaysOn />
    </Filter>
  )
}

const opsUserRowSx = (record, currentUserId) => {
  const isDeactivated = record.deleted_on !== null
  const isCurrentUser = record.id === currentUserId
  return {
    opacity: isDeactivated ? 0.4 : 1,
    backgroundColor: isCurrentUser ? alpha(darkTheme.palette.secondary.main, 0.1) : 'none',
    '&:hover': {
      backgroundColor: isCurrentUser ? `${alpha(darkTheme.palette.secondary.main, 0.2)} !important` : undefined,
    },
  }
}

const OpsUsersListLayout = () => {
  const { identity } = useGetIdentity()
  const currentUserId = identity?.id
  const translate = useTranslate()

  const resource = useResourceContext()
  const [hasDelete] = useResourcePermissions(resource, SYSTEM_PERMISSION_DELETE)

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemSx={(record) => opsUserRowSx(record, currentUserId)}
          icon={(record) => getUserFullName(record, { showInitials: true })}
          title={(record) => getUserFullName(record)}
          body={() => (
            <>
              <AdvancedTextField source="email" />
              <br />
              <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
            </>
          )}
        />
      }
      regularListLayout={
        <ListDatagrid rowSx={(record) => opsUserRowSx(record, currentUserId)}>
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
          <OrganisationField
            sortable={false}
            source="restricted_to_organisation_id"
            emptyText={translate('resources.ops-users.allOrganisations')}
          />
          <DateTooltipField source="created_on" addTime />
          <WrapperField source={translate('ra.action.edit')} label={false}>
            <AdvancedEditInDialogButton condition={(record) => record.deleted_on === null}>
              <OpsUserFormLayout />
            </AdvancedEditInDialogButton>
          </WrapperField>
          <WrapperField source={translate('ra.action.delete')} label={false}>
            {hasDelete && (
              <DeleteOpsUserButton condition={(record) => record.id !== currentUserId && record.deleted_on === null} />
            )}
          </WrapperField>
        </ListDatagrid>
      }
    />
  )
}

export default () => {
  return (
    <List
      empty={false}
      sort={config.options.defaultSort}
      filterDefaultValues={config.options.defaultFilterValues}
      filters={<OpsUsersFilters />}
      actions={
        <ListActions>
          <AdvancedCreateButton>
            <OpsUserFormLayout />
          </AdvancedCreateButton>
        </ListActions>
      }
    >
      <OpsUsersListLayout />
    </List>
  )
}

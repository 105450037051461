import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

import env from '../../config/env'
import { formatPriceForExport } from '../../utils'
import { formatDateTimeForExport } from '../../utils/dates'

const vehiclesExporter = (records, fetchRelatedRecords) => {
  let vehicles = []
  fetchRelatedRecords(records, 'organisation_id', 'organisations')
    .then((organisations) => {
      vehicles = records.map((record) =>
        record.organisation_id && organisations[record.organisation_id]
          ? { ...record, organisation_name: organisations[record.organisation_id].name }
          : record,
      )
    })
    .then(() =>
      fetchRelatedRecords(records, 'hub_id', 'hubs').then((hubs) => {
        vehicles = vehicles.map((vehicle) =>
          vehicle.hub_id && hubs[vehicle.hub_id] ? { ...vehicle, hub_name: hubs[vehicle.hub_id].name } : vehicle,
        )
      }),
    )
    .then(() => {
      const autonomyHeader = 'autonomy (km)'
      const averageConsumptionHeader = 'average_consumption (L/100km)'
      const chargingBufferHeader = 'charging_buffer (hours)'
      const maximumAutonomyHeader = 'maximum_autonomy (km)'
      const mileageHeader = 'mileage (km)'
      const pricePerKmHeader = `price_per_km (${env.CURRENCY_CODE})`
      const securityDepositAmountHeader = `security_deposit_amount (${env.CURRENCY_CODE})`
      const tankSizeHeader = 'tank_size (L)'
      const valueHeader = `catalog_price (${env.CURRENCY_CODE})`
      const volumeHeader = 'volume (m³)'

      const vehiclesForExport = vehicles.map((vehicle) => {
        // Remove non-used fields
        const {
          autonomy,
          average_consumption,
          charging_buffer,
          created_on,
          designation,
          ended_on,
          hub_id,
          maximum_autonomy,
          mileage,
          organisation_id,
          price_per_km,
          security_deposit_amount,
          started_on,
          status,
          tank_size,
          updated_on,
          value,
          volume,
          details,
          notes,
          battery_voltage,
          remaining_oil_life,
          dtcs,
          ...vehicleForExport
        } = vehicle
        // Add new fields (date formatting, price formatting & header change)
        vehicleForExport[autonomyHeader] = autonomy
        vehicleForExport[averageConsumptionHeader] = average_consumption
        vehicleForExport[chargingBufferHeader] = charging_buffer
        vehicleForExport.creation_date = formatDateTimeForExport(created_on)
        vehicleForExport.end_date = formatDateTimeForExport(ended_on)
        vehicleForExport.last_update_date = formatDateTimeForExport(updated_on)
        vehicleForExport[maximumAutonomyHeader] = maximum_autonomy
        vehicleForExport[mileageHeader] = mileage
        vehicleForExport.plate = designation
        vehicleForExport[pricePerKmHeader] = formatPriceForExport(price_per_km)
        vehicleForExport[securityDepositAmountHeader] = formatPriceForExport(security_deposit_amount)
        vehicleForExport.start_date = formatDateTimeForExport(started_on)
        vehicleForExport[tankSizeHeader] = tank_size
        vehicleForExport[valueHeader] = formatPriceForExport(value)
        vehicleForExport[volumeHeader] = volume
        vehicleForExport['details.code'] = details.map((detail) => detail.code)
        vehicleForExport['details.value'] = details.map((detail) => detail.value)

        return vehicleForExport
      })

      jsonExport(
        vehiclesForExport,
        {
          // order fields in the export
          headers: [
            'id',
            'organisation_name',
            'hub_name',
            'start_date',
            'end_date',
            'type',
            'blueprint_set',
            'brand',
            'model',
            'year',
            'plate',
            'identification_number',
            'picture',
            'installed',
            'gateway.code',
            'gateway.vehicle_id',
            autonomyHeader,
            mileageHeader,
            'locked',
            'transmission_type',
            'keyless',
            'number_of_seats',
            volumeHeader,
            valueHeader,
            'energy_type',
            tankSizeHeader,
            averageConsumptionHeader,
            maximumAutonomyHeader,
            'price_factor',
            securityDepositAmountHeader,
            pricePerKmHeader,
            chargingBufferHeader,
            'parking_spot',
            'details.code',
            'details.value',
            'information_document',
            'creation_date',
            'last_update_date',
          ],
        },
        (err, csv) => downloadCSV(csv, 'vehicles'),
      )
    })
}

export default vehiclesExporter

import CachedIcon from '@mui/icons-material/Cached'
import { IconButton, InputAdornment, Tooltip } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useEffect } from 'react'
import { required, TextInput, useGetOne } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import config from './config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPE_PERSONAL } from '../../config/vouchers'
import { getRandomString } from '../../utils'
import organisationsConfig from '../organisations/config'
import usersConfig from '../users/config'

export const VoucherCodeInput = ({ source = 'code', readOnly, ...props }) => {
  const { setValue } = useFormContext()
  const commonClasses = useCommonStyles()
  const translate = useTranslate()

  return (
    <TextInput
      {...props}
      source={source}
      readOnly={readOnly}
      onChange={(event) => setValue(source, event.target.value.replace(' ', '').toUpperCase())}
      InputProps={{
        endAdornment: readOnly ? null : (
          <InputAdornment position="end">
            <Tooltip title={translate('resources.vouchers.forms.tooltips.generateNewCode')}>
              <IconButton
                className={commonClasses.contrastColor}
                onClick={() => {
                  setValue(source, getRandomString())
                }}
                size="small"
                onMouseDown={(event) => {
                  event.preventDefault()
                }}
              >
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  )
}

const VoucherReferenceInput = ({ source, ...props }) => {
  const { setValue } = useFormContext()
  const values = useWatch()

  const organisationId = values[organisationsConfig.options.referenceKey]
  const userId = values[usersConfig.options.referenceKey]
  const voucherId = values[config.options.referenceKey]
  const { data: voucher } = useGetOne(config.name, { id: voucherId }, { enabled: Boolean(voucherId) })

  // We reset the voucher only when the type is personal and the newly selected org is different from the one linked to the voucher
  useEffect(() => {
    if (!voucher) return
    if (voucher.type === VOUCHER_TYPE_PERSONAL && organisationId !== voucher.organisation_id) {
      setValue(source, null)
    }
  }, [setValue, source, organisationId]) // eslint-disable-line react-hooks/exhaustive-deps

  // We reset the voucher only when the type is personal and the newly selected user is different from the one linked to the voucher
  useEffect(() => {
    if (!voucher) return
    if (voucher.type === VOUCHER_TYPE_PERSONAL && Boolean(userId) && userId !== voucher.user_id) {
      setValue(source, null)
    }
  }, [setValue, source, userId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdvancedReferenceInput
      {...props}
      source={source}
      filter={{ active: true, organisation_id: organisationId ?? null, user_id: userId ?? null }}
    />
  )
}

VoucherReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  validate: required(),
}

export default VoucherReferenceInput

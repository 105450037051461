import { InputAdornment, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { useTranslate } from 'ra-core'
import { RichTextInput } from 'ra-input-rich-text'
import { useEffect } from 'react'
import {
  CheckboxGroupInput,
  CREATE,
  FormDataConsumer,
  ImageField,
  ImageInput,
  minValue,
  number,
  NumberInput,
  required,
  ResourceContextProvider,
  TextInput,
  UPDATE,
  useGetIdentity,
  useRecordContext,
} from 'react-admin'

import { useApi } from '../../api/apiProvider'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE } from '../../config/addresses'
import { BOOKING_END_CHECK_TANK_FILLED_UP, BOOKING_END_CHECKS } from '../../config/bookingEndChecks'
import env from '../../config/env'
import { HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE } from '../../config/hubs'
import {
  ORGANISATION_DEFAULT_RENTAL_TCS_DISPLAY_NAME,
  ORGANISATION_DEFAULT_RENTAL_TCS_EMAIL_ADDRESS,
  ORGANISATION_DEFAULT_RENTAL_TCS_LEGAL_NAME,
  ORGANISATION_DEFAULT_RENTAL_TCS_START_DATE,
  ORGANISATION_EMAIL_NOTIFICATIONS,
} from '../../config/organisations'
import { OPS_USER_ROLE_OWNER } from '../../config/permissions'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { useSmallScreen } from '../../utils/theme'
import { validateEmail } from '../../utils/validators'
import { FormDivider } from '../common'
import { AdvancedSimpleForm, FormSubTitle } from '../common/forms'
import {
  HubAddressFillingMethodInput,
  HubAddressInput,
  HubPickUpAndDropOffHoursSection,
  transformValues as transformHubValues,
  validateGeofencing,
  validateLatitude,
  validateLongitude,
} from '../hubs/form'

const useStyles = makeStyles((theme) => ({
  notificationInputContainer: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  additionalHelperText: {
    color: theme.palette.text.secondary,
  },
  '@global': {
    '.ra-rich-text-input .ql-editor': {
      minWidth: COMMON_INPUT_WIDTH * 2 + theme.spacing(4) + 'px !important',
      maxHeight: '400px',
      overflowY: 'scroll',
    },
  },
}))

export const transformValues = ({
  default_rental_terms_and_conditions,
  form_type,
  has_email_notifications,
  hub,
  legal_name,
  rental_terms_and_conditions,
  requires_booking_end_checks,
  requires_missing_fuel_fine,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        booking_end_checks: requires_booking_end_checks ? restValues.booking_end_checks : [],
        missing_fuel_fine:
          restValues.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && requires_missing_fuel_fine
            ? restValues.missing_fuel_fine
            : null,
        ...ORGANISATION_EMAIL_NOTIFICATIONS.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.code]: has_email_notifications ? restValues[curr.code] ?? false : false,
          }),
          {},
        ),
        rental_terms_and_conditions:
          form_type === CREATE
            ? default_rental_terms_and_conditions
                ?.replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_LEGAL_NAME, legal_name)
                .replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_DISPLAY_NAME, restValues.name)
                .replaceAll(
                  ORGANISATION_DEFAULT_RENTAL_TCS_START_DATE,
                  DateTime.now().toLocaleString(DateTime.DATE_SHORT),
                )
                .replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_EMAIL_ADDRESS, restValues.contact_email)
            : rental_terms_and_conditions,
        hub: Boolean(hub) ? transformHubValues(hub) : null,
      }
    : restValues

const validateFees = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const OrganisationFormLayout = ({ type = UPDATE }) => {
  const record = useRecordContext()
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const translate = useTranslate()

  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => {
    fetchMeInfo()
  }, [fetchMeInfo])

  if (!identity || !meInfo) return null

  const bookingEndChecksChoices = Object.entries(BOOKING_END_CHECKS).map(([k, v]) => ({ id: k, name: v }))

  const defaultValues = { form_type: type }
  if (type === CREATE) {
    defaultValues.default_rental_terms_and_conditions = meInfo.default_rental_terms_and_conditions
    defaultValues.hub = {
      address_filling_method: HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
      [HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE]: false,
    }
  } else {
    defaultValues.picture_data = record.picture ? { src: record.picture } : null
    defaultValues.requires_booking_end_checks = record.booking_end_checks.length > 0
    defaultValues.requires_missing_fuel_fine = !!record.missing_fuel_fine
    defaultValues.has_email_notifications = ORGANISATION_EMAIL_NOTIFICATIONS.some((n) => record[n.code] === true)
  }

  const shouldDisplayRentalTermsAndConditions = identity.role === OPS_USER_ROLE_OWNER && type === UPDATE

  return (
    <AdvancedSimpleForm defaultValues={defaultValues} toolbar={<BasicFormToolbar formType={type} />} type={type}>
      <FormSubTitle text="identification" isFirstChild />

      <ImageInput
        className={classnames(commonClasses.commonInput, commonClasses.imageInput)}
        source="picture_data"
        labelSingle="mymove.dropOrSelectYourFileHere"
        helperText={false}
        accept="image/*"
        validate={required()}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <FormDivider />

      <TextInput source="name" validate={required()} />
      {type === CREATE && <TextInput source="legal_name" validate={required()} />}
      <TextInput
        type="email"
        source="contact_email"
        validate={validateEmail()}
        helperText="resources.organisations.forms.helperTexts.contact_email"
      />

      <FormSubTitle text="fleetUsage" />

      <AdvancedSelectInput source="address_required" />
      <AdvancedSelectInput source="requires_face_check" />
      <AdvancedSelectInput source="requires_parking_spot" />
      <AdvancedSelectInput source="requires_damage_report_prompt" />

      <FormDivider />

      <AdvancedSelectInput source="requires_booking_end_checks" />
      <FormDataConsumer>
        {({ formData }) =>
          formData.requires_booking_end_checks ? (
            <CheckboxGroupInput
              source="booking_end_checks"
              choices={bookingEndChecksChoices}
              validate={required('resources.organisations.forms.validations.booking_end_checks.required')}
              helperText="resources.organisations.forms.helperTexts.booking_end_checks"
              label={false}
              row={false}
            />
          ) : null
        }
      </FormDataConsumer>

      <FormSubTitle
        text="fines"
        condition={(formData) => formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP)}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && (
            <AdvancedBooleanInput helperText={false} source="requires_missing_fuel_fine" sx={{ width: 330 }} />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) &&
          formData.requires_missing_fuel_fine && (
            <NumberInput
              source="missing_fuel_fine.operational_fees"
              validate={validateFees}
              format={(v) => (v ? v / 100 : null)}
              parse={(v) => v * 100}
              step={0.01}
              min={0}
              InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) &&
          formData.requires_missing_fuel_fine && (
            <NumberInput
              source="missing_fuel_fine.fuel_fees"
              validate={validateFees}
              format={(v) => (v ? v / 100 : null)}
              parse={(v) => v * 100}
              step={0.01}
              min={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL + '/L'}</InputAdornment>,
              }}
            />
          )
        }
      </FormDataConsumer>

      <FormSubTitle text="notifications" />

      <AdvancedSelectInput source="has_email_notifications" />
      <FormDataConsumer>
        {({ formData }) =>
          formData.has_email_notifications &&
          ORGANISATION_EMAIL_NOTIFICATIONS.map(({ code, recommended, ...rest }) => (
            <div key={code} className={classes.notificationInputContainer}>
              <AdvancedBooleanInput
                {...rest}
                source={code}
                className={isSmallScreen ? null : commonClasses.doubleInput}
              />
              {recommended && (
                <Typography variant="caption" display="block" className={classes.additionalHelperText}>
                  {translate('resources.organisations.emailNotifications.recommended')}
                </Typography>
              )}
            </div>
          ))
        }
      </FormDataConsumer>

      <FormSubTitle text="legalContent" condition={() => shouldDisplayRentalTermsAndConditions} />

      {shouldDisplayRentalTermsAndConditions && (
        <RichTextInput
          sx={{ marginLeft: '16px', '& .RaRichTextInput-editorContent': { maxHeight: '600px', overflow: 'scroll' } }}
          source="rental_terms_and_conditions"
          validate={required()}
          helperText={false}
        />
      )}

      {type === CREATE && (
        <>
          <FormDivider />

          <Typography variant="h6" sx={{ margin: '16px 0 0 16px' }}>
            {translate('resources.organisations.forms.create.firstHubCreation')}
          </Typography>

          <ResourceContextProvider value="hubs">
            <FormSubTitle text="identification" />
          </ResourceContextProvider>

          <TextInput source="hub.name" label="resources.hubs.fields.name" validate={required()} />

          <ResourceContextProvider value="hubs">
            <FormSubTitle text="geolocation" />
          </ResourceContextProvider>

          <HubAddressFillingMethodInput
            source="hub.address_filling_method"
            addressSource="hub.address"
            positionSource="hub.position"
          />
          <HubAddressInput target="hub" />
          <FormDataConsumer>
            {({ formData }) => (
              <NumberInput
                source="hub.position.latitude"
                label="resources.hubs.fields.position.latitude"
                validate={validateLatitude}
                min={-90}
                max={90}
                readOnly={formData.hub.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
                InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => (
              <NumberInput
                source="hub.position.longitude"
                label="resources.hubs.fields.position.longitude"
                validate={validateLongitude}
                min={-180}
                max={180}
                readOnly={formData.hub.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
                InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
              />
            )}
          </FormDataConsumer>

          <FormDivider />

          <FormDataConsumer>
            {({ formData }) => (
              <NumberInput
                source="hub.geofencing"
                label="resources.hubs.fields.geofencing"
                validate={validateGeofencing}
                min={0}
                helperText="resources.hubs.forms.helperTexts.geofencing"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {translate('mymove.units.distance.meters', get(formData, 'hub.geofencing') || 1)}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </FormDataConsumer>

          <ResourceContextProvider value="hubs">
            <FormSubTitle text="schedules" />
          </ResourceContextProvider>

          <AdvancedBooleanInput
            source={`hub.${HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE}`}
            label={`resources.hubs.fields.${HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE}`}
            helperText={`resources.hubs.forms.helperTexts.${HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE}`}
            className={isSmallScreen ? null : commonClasses.doubleInput}
            sx={{ marginBottom: 2 }}
          />

          <HubPickUpAndDropOffHoursSection target="hub" />
        </>
      )}
    </AdvancedSimpleForm>
  )
}

export default OrganisationFormLayout

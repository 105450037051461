import VehicleOffIcon from '../../components/icons/VehicleOffIcon'
import PeriodField from '../../components/PeriodField'
import ReferenceValue from '../../components/ReferenceValue'
import { useCommonStyles } from '../../config/theme'
import vehiclesConfig from '../vehicles/config'

export default {
  // TODO later in sync with backend: modify maintenances name & referenceKey
  name: 'maintenances',
  recordRepresentation: () => {
    const VehicleUnavailabilityRecordRepresentation = () => {
      const commonClasses = useCommonStyles()
      return (
        <>
          <ReferenceValue reference={vehiclesConfig.name} emptyText={null} />
          {'\u00a0•\u00a0'}
          <PeriodField
            addTime
            className={commonClasses.inheritedFont}
            endedOnSource="ended_on"
            startedOnSource="started_on"
          />
        </>
      )
    }
    return <VehicleUnavailabilityRecordRepresentation />
  },
  options: {
    referenceKey: 'maintenance_id',
    defaultSort: { field: 'started_on', order: 'DESC' },
    references: ['organisations', 'vehicles'],
  },
  icon: VehicleOffIcon,
}

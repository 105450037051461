import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ComputerIcon from '@mui/icons-material/Computer'
import ContactlessIcon from '@mui/icons-material/Contactless'
import PublicIcon from '@mui/icons-material/Public'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
export const EVENT_CHANNEL_GRAPHQL = 'GRAPHQL'
export const EVENT_CHANNEL_INTERNAL = 'INTERNAL'
export const EVENT_CHANNEL_OPS = 'OPS'
export const EVENT_CHANNEL_PAPI = 'PAPI'
export const EVENT_CHANNEL_SCIM = 'SCIM'
export const EVENT_CHANNEL_WEBHOOK = 'WEBHOOK'

export const EVENT_CHANNELS = [
  EVENT_CHANNEL_GRAPHQL,
  EVENT_CHANNEL_INTERNAL,
  EVENT_CHANNEL_OPS,
  EVENT_CHANNEL_PAPI,
  EVENT_CHANNEL_SCIM,
  EVENT_CHANNEL_WEBHOOK,
]

export const EVENT_ACTION_FORCE_LOCK_VEHICLE = 'FORCE_LOCK_VEHICLE'
export const EVENT_ACTION_FORCE_UNLOCK_VEHICLE = 'FORCE_UNLOCK_VEHICLE'
export const EVENT_ACTION_ARCHIVE_ORGANISATION = 'ARCHIVE_ORGANISATION'
export const EVENT_ACTION_START_BOOKING = 'START_BOOKING'
export const EVENT_ACTION_END_BOOKING = 'END_BOOKING'
export const EVENT_ACTION_CANCEL_BOOKING = 'CANCEL_BOOKING'
export const EVENT_ACTION_CLOSE_USER_ACCOUNT = 'CLOSE_USER_ACCOUNT'
export const EVENT_ACTION_REOPEN_USER_ACCOUNT = 'REOPEN_USER_ACCOUNT'
export const EVENT_ACTION_CHARGE_EXTRA_PAYMENT = 'CHARGE_EXTRA_PAYMENT'
export const EVENT_ACTION_REFUND_PAYMENT = 'REFUND_PAYMENT'
export const EVENT_ACTION_RELEASE_PRE_AUTHORIZATION_PAYMENT = 'RELEASE_PRE_AUTHORIZATION_PAYMENT'
export const EVENT_ACTION_CREATE = 'CREATE'
export const EVENT_ACTION_UPDATE = 'UPDATE'
export const EVENT_ACTION_DELETE = 'DELETE'

export const EVENT_ACTIONS = [
  EVENT_ACTION_FORCE_LOCK_VEHICLE,
  EVENT_ACTION_FORCE_UNLOCK_VEHICLE,
  EVENT_ACTION_ARCHIVE_ORGANISATION,
  EVENT_ACTION_START_BOOKING,
  EVENT_ACTION_END_BOOKING,
  EVENT_ACTION_CANCEL_BOOKING,
  EVENT_ACTION_CLOSE_USER_ACCOUNT,
  EVENT_ACTION_REOPEN_USER_ACCOUNT,
  EVENT_ACTION_CHARGE_EXTRA_PAYMENT,
  EVENT_ACTION_REFUND_PAYMENT,
  EVENT_ACTION_RELEASE_PRE_AUTHORIZATION_PAYMENT,
  EVENT_ACTION_CREATE,
  EVENT_ACTION_UPDATE,
  EVENT_ACTION_DELETE,
]
export const EVENT_CHANNEL_ICONS = {
  [EVENT_CHANNEL_GRAPHQL]: SmartphoneIcon,
  [EVENT_CHANNEL_INTERNAL]: SettingsSuggestIcon,
  [EVENT_CHANNEL_OPS]: ComputerIcon,
  [EVENT_CHANNEL_PAPI]: PublicIcon,
  [EVENT_CHANNEL_SCIM]: AccountTreeIcon,
  [EVENT_CHANNEL_WEBHOOK]: ContactlessIcon,
}

import ArrowIcon from '@mui/icons-material/ArrowForward'
import classnames from 'classnames'

import DateTooltipField from './DateTooltipField'
import { useCommonStyles } from '../config/theme'

const PeriodField = ({
  startedOnSource = 'startedOn',
  endedOnSource = 'endedOn',
  label = 'resources.common.fields.period',
  ...props
}) => {
  const classes = useCommonStyles()

  return (
    <span className={classnames(classes.textWithSmallIcons, props.className)}>
      <DateTooltipField {...props} source={startedOnSource} />
      <ArrowIcon className={props.className} sx={{ margin: '0 !important' }} />
      <DateTooltipField {...props} source={endedOnSource} />
    </span>
  )
}

export default PeriodField

import { required } from 'ra-core'
import { SelectInput, useResourceContext } from 'react-admin'

import { useHelperText } from '../resources/common/forms'

const AdvancedSelectInput = (props) => {
  const resource = useResourceContext()
  const helperText = useHelperText(props.source)

  const booleanChoices = [
    { value: false, name: 'ra.boolean.false' },
    { value: true, name: 'ra.boolean.true' },
  ]

  return (
    <SelectInput
      choices={booleanChoices}
      helperText={helperText}
      label={`resources.${resource}.fields.${props.source}`}
      optionValue="value"
      validate={required()}
      {...props}
    />
  )
}

export default AdvancedSelectInput

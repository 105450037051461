import { CardHeader } from '@mui/material'
import { useResourceContext } from 'react-admin'

import ShowButton from './ShowButton'
import { useCommonStyles } from '../config/theme'
import { CardTitle } from '../resources/common'

const ShowCardHeader = ({ action, hasRedirect = false, title, titleArgs, ...props }) => {
  const resource = useResourceContext(props)
  const commonClasses = useCommonStyles()
  return (
    <CardHeader
      title={<CardTitle text={title ?? `resources.${resource}.show.titles.main`} args={titleArgs} />}
      action={action ?? (hasRedirect && <ShowButton resource={resource} />)}
      className={commonClasses.titleContainer}
    />
  )
}

export default ShowCardHeader

import get from 'lodash/get'
import { FunctionField } from 'react-admin'

export const getVehicleName = (record) => `${get(record, 'brand', '')} ${get(record, 'model', '')}`

const VehicleNameField = (props) => (
  <FunctionField label="resources.vehicles.fields.name" render={getVehicleName} {...props} />
)

export default VehicleNameField

import get from 'lodash/get'
import { NumberField, useRecordContext } from 'react-admin'

const AdvancedNumberField = (props) => {
  const record = useRecordContext(props)
  const value = get(record, props.source) || 'n/a'
  return <NumberField {...props} record={{ ...record, [props.source]: value }} />
}

export default AdvancedNumberField

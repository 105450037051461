import config from './config'
import Create from './create'
import Edit from './edit'
import List from './list'
import Show from './show'

export default {
  ...config,
  config,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
}

import HubFormLayout, { transformValues } from './form'
import { HubTitle } from './show'
import GenericCreateForm from '../common/create'

export default () => (
  <GenericCreateForm
    titleComponent={<HubTitle />}
    formLayoutComponent={<HubFormLayout />}
    transformValues={transformValues}
  />
)

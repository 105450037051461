import { Typography } from '@mui/material'
import { EditDialog, EditInDialogButton } from '@react-admin/ra-form-layout'
import { useTranslate } from 'ra-core'
import React, { cloneElement, useState } from 'react'
import {
  Edit,
  EditButton,
  ResourceContextProvider,
  UPDATE,
  useCreatePath,
  useNotify,
  useRecordContext,
  useResourceContext,
} from 'react-admin'

import { useHandleRedirection } from './hooks'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'

export default ({ formLayoutComponent, transformValues }) => {
  const handleRedirection = useHandleRedirection()
  const notify = useNotify()
  const resource = useResourceContext()

  const onSuccess = async (record) => {
    await handleRedirection(record)
    await notify(`resources.${resource}.forms.update.success`)
  }
  const onError = (error) => notify(error.message, { type: 'error' })

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }} transform={transformValues}>
      {formLayoutComponent}
    </Edit>
  )
}

export const AdvancedEditInDialogButton = ({ children: formLayout, condition, title, ...props }) => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const translate = useTranslate()
  const [hasEdit] = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  title = title || `resources.${resource}.forms.update.title`

  const notify = useNotify()
  const onSuccess = () => notify(`resources.${resource}.forms.update.success`)
  const onError = (error) => notify(error.message, { type: 'error' })

  if (!record || (typeof condition === 'function' && !condition(record))) {
    return null
  }

  return (
    hasEdit && (
      <EditInDialogButton
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess, onError }}
        onClick={(e) => e.stopPropagation()}
        sx={{ '& .MuiDialogTitle-root': { borderBottom: '1px solid', borderColor: 'divider' } }}
        title={<Typography variant="h6">{translate(title)}</Typography>}
        {...props}
      >
        {cloneElement(formLayout, { type: UPDATE })}
      </EditInDialogButton>
    )
  )
}

export const AdvancedEditButton = ({ children: formLayout, id, title, to, ...props }) => {
  const createPath = useCreatePath()
  const resource = useResourceContext(props)
  const [hasEdit] = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  const isSmallScreen = useSmallScreen()

  if (!hasEdit) {
    return null
  }

  return isSmallScreen ? (
    <EditButton to={to ?? { pathname: createPath({ resource, id, type: 'edit' }) }} />
  ) : (
    <AdvancedEditInDialogButton title={title} {...props}>
      {formLayout}
    </AdvancedEditInDialogButton>
  )
}

export const useEditResource = (resource, formLayout, transformValues) => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (record) => setEditPopupState({ isOpen: true, values: record })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })

  const dialog = (
    <ResourceContextProvider value={resource}>
      <EditDialog
        close={handleEditPopupClose}
        id={editPopupState.values.id}
        isOpen={editPopupState.isOpen}
        mutationMode="pessimistic"
        sx={{ '& .MuiDialogTitle-root': { borderBottom: '1px solid', borderColor: 'divider' } }}
        title={`resources.${resource}.forms.update.title`}
        transform={transformValues}
      >
        {cloneElement(formLayout, { record: editPopupState.values, type: UPDATE })}
      </EditDialog>
    </ResourceContextProvider>
  )
  return [handleEditPopupOpen, dialog]
}

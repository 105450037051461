import { ReferenceField, useRecordContext } from 'react-admin'

import Blueprint from '../../components/Blueprint'
import damageBlueprintsConfig from '../damageBlueprints/config'

const MarkerField = ({
  source = damageBlueprintsConfig.options.referenceKey,
  reference = damageBlueprintsConfig.name,
  link = false,
  ...props
}) => {
  const record = useRecordContext()
  return (
    <ReferenceField source={source} reference={reference} link={link}>
      <Blueprint reports={[record]} {...props} />
    </ReferenceField>
  )
}

export default MarkerField

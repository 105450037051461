import { NumberField, ReferenceField, SimpleShowLayout } from 'react-admin'

import config from './config'
import { DiscountField } from './field'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UserFullNameField from '../../components/UserFullNameField'
import { VOUCHER_TYPES } from '../../config/vouchers'
import bookingsConfig from '../bookings/config'
import { BookingsListLayout } from '../bookings/list'
import { ListReference } from '../common/list'
import { useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'

const VoucherShowLayout = () => {
  const showClasses = useShowStyles()
  return (
    <>
      <ShowCardHeader />
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <AdvancedTextField source="code" />
        <AdvancedTextField source="name" />
        <DiscountField />
        <DateTooltipField source="created_on" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <AdvancedTextField source="type" map={VOUCHER_TYPES} />
        <ReferenceField source="user_id" reference="users" link="show" emptyText="n/a">
          <UserFullNameField />
        </ReferenceField>
        <OrganisationField emptyText="n/a" />
      </SimpleShowLayout>
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <DateTooltipField source="start_date" addTime />
        <DateTooltipField source="end_date" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout className={showClasses.fieldContainer}>
        <NumberField source="number_of_entries" />
        <NumberField source="number_of_uses" />
      </SimpleShowLayout>
    </>
  )
}

const VoucherAside = () => (
  <ListReference
    reference="bookings"
    target={config.options.referenceKey}
    sort={bookingsConfig.options.defaultSort}
    filter={bookingsConfig.options.defaultFilterValues}
    showPagination
  >
    <BookingsListLayout />
  </ListReference>
)

export default () => (
  <AdvancedShow aside={<VoucherAside />}>
    <VoucherShowLayout />
  </AdvancedShow>
)

import { Box, Typography } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect } from 'react'
import { useRecordContext, useReference } from 'react-admin'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import Blueprint from '../../components/Blueprint'
import damageBlueprintsConfig from '../damageBlueprints/config'

const MarkerInput = ({ onNewMarker }) => {
  const { formState, setValue } = useFormContext()
  const record = useRecordContext()
  const translate = useTranslate()
  const [blueprintId, markerXRatio] = useWatch({ name: ['blueprint_id', 'marker_x_ratio'] })

  useEffect(() => {
    if (!blueprintId) {
      setValue('marker_x_ratio', undefined)
      setValue('marker_y_ratio', undefined)
    }
  }, [blueprintId, setValue])

  const { referenceRecord: blueprint } = useReference({
    reference: damageBlueprintsConfig.name,
    id: blueprintId,
    options: { enabled: Boolean(blueprintId) },
  })

  const setMarker = useCallback(
    (marker) => {
      setValue('marker_x_ratio', marker.xRatio)
      setValue('marker_y_ratio', marker.yRatio)
      onNewMarker()
    },
    [onNewMarker, setValue],
  )

  return blueprint ? (
    <Box ml={2} mt={2}>
      <Blueprint
        blueprint={blueprint}
        editable={true}
        onNewMarker={setMarker}
        reports={record?.marker_x_ratio ? [record] : []}
      />
      {formState.isSubmitted && !markerXRatio && (
        <Typography variant="caption" display="block" color="error.main" ml={0.5} mt={1}>
          {translate('ra.validation.required')}
        </Typography>
      )}
      <Controller name="marker_x_ratio" render={() => null} rules={{ required: true }} />
      <Controller name="marker_y_ratio" render={() => null} rules={{ required: true }} />
    </Box>
  ) : null
}

export default MarkerInput

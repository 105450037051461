import get from 'lodash/get'
import { FunctionField } from 'react-admin'

export const getUserFullName = (
  record,
  { showFirstNameInitial = false, showLastNameInitial = false, showInitials = false } = {},
) => {
  let firstName = get(record, 'first_name', '')
  let lastName = get(record, 'last_name', '')
  if (showInitials) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }
  if (showFirstNameInitial && firstName) {
    firstName = firstName.charAt(0).toUpperCase() + '.'
  }
  if (showLastNameInitial && lastName) {
    lastName = lastName.charAt(0).toUpperCase() + '.'
  }
  const isEmpty = firstName + lastName === ''
  return isEmpty ? '\u00a0' : `${firstName} ${lastName}`.trim()
}

const UserFullNameField = ({ showFirstNameInitial, showLastNameInitial, ...props }) => {
  const options = { showFirstNameInitial, showLastNameInitial }
  return <FunctionField label="Name" render={(record) => getUserFullName(record, options)} {...props} />
}

export default UserFullNameField
